import React from "react";
import { BsFillTelephoneFill, BsWhatsapp } from "react-icons/bs";
import withDataListingPerumahan from "./utils/withDataListingPerumahan";

function CardPartner(prop: any) {
  return (
    <div className="h-fit bg-white px-3 py-3 rounded-2xl border-2 border-[#080a3f]">
      {/* foto */}
      <div className="text-center text-white mt-1 py-1 grid grid-cols-2 justify-items-center ">
        <div className="flex items-center text-left text-[12px] my-2 lg:text-sm italic text-black col-span-2 justify-self-start mx-5">
          <div className="h-24 mr-3 aspect-square bg-white rounded-full w-fit overflow-hidden border-2 border-blue-950">
            <img src={prop.foto} alt={prop.namaPartner} />
          </div>
          <div>
            {/* <p className="not-italic font-bold ">
                      Hubungi Marketing Terbaik Kami :
                    </p> */}
            <p className="not-italic font-bold ">
              <span className=" text-xl">{prop.namaPartner}</span> (
              {prop.namaPopuler}) <br /> {prop.nomor}
            </p>
            {prop.alamat}
          </div>
        </div>
        <div className="w-auto text-black col-span-2 text-[12px] mx-2 lg:text-sm text-red-500 italic">
          {/* <p>
                    percayakan survey dan pembelian anda kepada <u>Rivandy</u>
                    , <br />{" "}
                    <b>"jaminan pelanggan puas legalitas perumahan jelas</b>"
                  </p> */}
        </div>
        <div className="w-auto  flex space-x-2 col-span-2 text-[12px] mx-2 lg:text-sm italic">
          <h6 className="space-x-1  bg-white border border-[#080a3f]  text-black w-fit px-5 py-2 rounded-lg hover:bg-gray-200 text-sm  lg:text-base flex justify-center items-center my-2 cursor-pointer">
            <div
              className="flex justify-around items-center gap-3"
              onClick={prop.handleClikOpenLinkAffiliate}
            >
              {/* <BsFillTelephoneFill /> */}
              <h1 className="">View Listing</h1>
            </div>
          </h6>
          <h6 className="space-x-1  bg-white border border-[#080a3f]  text-black w-fit px-5 py-2 rounded-lg hover:bg-gray-200 text-sm  lg:text-base flex justify-center items-center my-2 cursor-pointer">
            <div
              className="flex justify-around items-center gap-3"
              onClick={prop.handleClikCall}
            >
              <BsFillTelephoneFill />
              <h1 className="">Telepon</h1>
            </div>
          </h6>
          <h6
            className="space-x-3 bg-green-600 w-fit px-5 py-2 rounded-lg hover:bg-green-800 text-sm lg:text-base flex justify-center items-center  my-2 cursor-pointer"
            onClick={prop.handleClickChatDetailPage}
          >
            <BsWhatsapp />
            <a href="/">Chat</a>
          </h6>
        </div>
      </div>
    </div>
  );
}

export default withDataListingPerumahan(CardPartner);
