import React from "react";
import { RiMenuUnfoldLine } from "react-icons/ri";
import NavMenuHorizontal from "../../components/NavMenuHorizontal";
import Logo from "../../components/Logo";
import Menu from "../../components/Menu";
import { BsFillTelephoneFill, BsWhatsapp } from "react-icons/bs";
import CardPartner from "../../components/CardPartner";
import { dataMember } from "../../data/member";
import { Text } from "react-font";
import HeaderMenu from "../../components/HeaderMenu";
import withDataListingPerumahan from "../../components/utils/withDataListingPerumahan";

function Partners(prop: any) {
  const displayMember = dataMember
    .sort(() => Math.random() - Math.random())
    .sort(() => Math.random() - Math.random())
    .sort(() => Math.random() - Math.random());
  return (
    <>
      {/* Header */}
      <HeaderMenu mitra={prop.popularName} />

      {/* Title */}
      <section>
        <div>
          <div className=" flex justify-center py-5">
            <p className="text-3xl font-extrabold">
              {" "}
              <Text family="Montserrat">Partner Terbaik Kami</Text>
            </p>
          </div>
        </div>
      </section>

      {/* Card Container */}
      <section className="">
        <div className="h-screen w-screen  flex justify-center">
          <div className="w-full h-fit lg:w-[80%]  px-1 md:px-5 py-5 flex justify-center">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-2 md:gap-5 ">
              {Object.keys(displayMember).map((val, key) => {
                if (
                  new Date(displayMember[key].expiredDateMember).valueOf() >=
                  new Date().valueOf()
                ) {
                  return (
                    <CardPartner
                      memberID={displayMember[key].id_member}
                      foto={displayMember[key].foto}
                      namaPartner={displayMember[key].nama}
                      namaPopuler={displayMember[key].namaPopuler}
                      nomor={displayMember[key].nomorDisplay}
                      nomorChatCallPartner={displayMember[key].nomor}
                      alamat={displayMember[key].alamat}
                    />
                  );
                }
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default withDataListingPerumahan(Partners);
