import React from "react";
import Housing from "./pages/housing";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DetailPage from "./pages/detailpage";
import { dataMember } from "./data/member";
import ContactUsPage from "./pages/contactus/ContactUsPage";
import Login from "./pages/login";
import Partners from "./pages/partners";

function App() {
  let member = "";
  for (let i = 0; i < dataMember.length; i++) {
    if (
      window.location.pathname.split("/").includes(dataMember[i]["id_member"])
    ) {
      member = dataMember[i]["id_member"];
      console.log("Data Member : ", dataMember[i]["id_member"]);
    }
  }
  return (
    <>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/" element={<Housing />} />
          <Route path="/:member" element={<Housing />} />
          <Route path="/detail-page/" element={<DetailPage />} />
          <Route path="/detail-page/:member" element={<DetailPage />} />
          <Route path="/contact-us/" element={<ContactUsPage />} />
          <Route path="/contact-us/:member" element={<ContactUsPage />} />
          <Route path="/partner" element={<Partners />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
