export const dataMember = [
  // {
  //   id_member: "",
  //   nama: "Rivandy",
  //   namaPopuler: "Zetaffpro",
  //   nomor: "+6282245452288",
  //   nomorDisplay: "0822 - 4545 - 2288",
  //   sitename: `${process.env.PUBLIC_URL}`,
  //   foto: "/media/foto-member/rivandy.jpeg",
  //   exceptionShowListing: [""],
  //   registrasiAwal: "30 August, 2023",
  //   expiredDateMember: "3 September, 2100",
  //   masaBerlangganan: "",
  // },
  {
    id_member: "zetaffpro",
    nama: "Rivandy",
    namaPopuler: "Zetaffpro",
    nomor: "+6282245452288",
    nomorDisplay: "0822 - 4545 - 2288",
    sitename: `${process.env.PUBLIC_URL}`,
    foto: "/media/foto-member/rivandy.jpeg",
    alamat: "Sidoarjo",
    exceptionShowListing: [""],
    registrasiAwal: "30 August, 2023",
    expiredDateMember: "3 September, 2100",
    masaBerlangganan: "",
  },
  // {
  //   id_member: "coba",
  //   nama: "coba",
  //   namaPopuler: "Zetaffpro",
  //   nomor: "+6282245452288",
  //   nomorDisplay: "0822 - 4545 - 1234956",
  //   sitename: `${process.env.PUBLIC_URL}`,
  //   foto: "https://cdn0-production-images-kly.akamaized.net/cTZD557nr36ezCQK2TdERuJqZsQ=/1200x900/smart/filters:quality(75):strip_icc():format(webp)/kly-media-production/medias/3083593/original/015346600_1584951361-bright-daylight-environment-forest-240040.jpg",
  //   exceptionShowListing: ["DE NAILA VILLAGE 2", "OMAH KWENI"],
  //   registrasiAwal: "22 August, 2023  00:00:00",
  //   expiredDateMember: "3 August, 2023  00:00:00",
  //   masaBerlangganan: "",
  // },
  // {
  //   id_member: "coba1",
  //   nama: "coba1",
  //   namaPopuler: "Zetaffpro",
  //   nomor: "+6282245452288",
  //   nomorDisplay: "0822 - 4545 - 1234956",
  //   sitename: `${process.env.PUBLIC_URL}`,
  //   foto: "https://cdn0-production-images-kly.akamaized.net/cTZD557nr36ezCQK2TdERuJqZsQ=/1200x900/smart/filters:quality(75):strip_icc():format(webp)/kly-media-production/medias/3083593/original/015346600_1584951361-bright-daylight-environment-forest-240040.jpg",
  //   exceptionShowListing: [
  //     "DE NAILA VILLAGE 2",
  //     "OMAH KWENI",
  //     "SURYA GARDEN",
  //     "ROYAL EMRAN",
  //   ],
  //   registrasiAwal: "22 August, 2023  00:00:00",
  //   expiredDateMember: "3 August, 2024  00:00:00",
  //   masaBerlangganan: "",
  // },
  // {
  //   id_member: "",
  //   nama: "Sanusi",
  //   namaPopuler: "Ahsanpro",
  //   nomor: "+6282145962715",
  //   nomorDisplay: "0821 - 4596 - 2715",
  //   sitename: "".concat(""),
  //   foto: "/media/foto-member/sanusi.jpeg",
  //   exceptionShowListing: [""],
  //   registrasiAwal: "30 August, 2023",
  //   expiredDateMember: "3 September, 2100",
  //   masaBerlangganan: "",
  // },
  {
    id_member: "sanspro",
    nama: "Sanusi",
    namaPopuler: "Ahsanpro",
    nomor: "+6282145962715",
    nomorDisplay: "0821 - 4596 - 2715",
    sitename: `${process.env.PUBLIC_URL}/sanspro`,
    foto: "/media/foto-member/sanusi.jpeg",
    alamat: "Sidoarjo",
    exceptionShowListing: [""],
    registrasiAwal: "30 August, 2023",
    expiredDateMember: "3 September, 2100",
    masaBerlangganan: "",
  },
  {
    id_member: "edypro",
    nama: "Edy",
    namaPopuler: "Fijaypro",
    nomor: "+6282333329078",
    nomorDisplay: "0823 - 3332 - 9078",
    sitename: `${process.env.PUBLIC_URL}/edypro`,
    foto: "/media/foto-member/edy.jpeg",
    alamat: "Surabaya",
    exceptionShowListing: [""],
    registrasiAwal: "1 September, 2023",
    expiredDateMember: "1 September, 2024",
    masaBerlangganan: "",
  },
  {
    id_member: "arifpro",
    nama: "Arif Wicaksono",
    namaPopuler: "Arif W",
    nomor: "+6281231644494",
    nomorDisplay: "0812 - 3164 - 4494",
    sitename: `${process.env.PUBLIC_URL}/arifpro`,
    foto: "/media/foto-member/arif.jpeg",
    alamat: "Sidoarjo",
    exceptionShowListing: [""],
    registrasiAwal: "3 Oktober, 2023",
    expiredDateMember: "4 March, 2024",
    masaBerlangganan: "",
  },
  {
    id_member: "sachur",
    nama: "Sachur Juliono",
    namaPopuler: "Sachur",
    nomor: "+6282146620300",
    nomorDisplay: "0821 - 4662 - 0300",
    sitename: `${process.env.PUBLIC_URL}/sachur`,
    foto: "/media/foto-member/sachur.jpeg",
    alamat: "Sidoarjo",
    exceptionShowListing: [""],
    registrasiAwal: "8 Oktober, 2023",
    expiredDateMember: "9 January, 2024",
    masaBerlangganan: "",
  },
  {
    id_member: "husin",
    nama: "Moch Husin",
    namaPopuler: "Husin",
    nomor: "+6285130409997",
    nomorDisplay: "0851 - 3040 - 9997",
    sitename: `${process.env.PUBLIC_URL}/husin`,
    foto: "/media/foto-member/husin.jpeg",
    alamat: "Surabaya",
    exceptionShowListing: [""],
    registrasiAwal: "27 Oktober, 2023",
    expiredDateMember: "30 February, 2024",
    masaBerlangganan: "",
  },
  {
    id_member: "zpro",
    nama: "Zainul Arifin",
    namaPopuler: "Z-Pro",
    nomor: "+6281230714668",
    nomorDisplay: "0812-3071- 4668",
    sitename: `${process.env.PUBLIC_URL}/zpro`,
    foto: "/media/foto-member/zainul.jpeg",
    alamat: "Surabaya",
    exceptionShowListing: [""],
    registrasiAwal: "22 November, 2023",
    expiredDateMember: "22 May, 2024",
    masaBerlangganan: "",
  },

  {
    id_member: "catur",
    nama: "Catur Muji R.",
    namaPopuler: "Mr Catur",
    nomor: "+6285645942509",
    nomorDisplay: "0856 - 4594 - 2509",
    sitename: `${process.env.PUBLIC_URL}/catur`,
    foto: "/media/foto-member/catur.jpeg",
    alamat: "Sidoarjo",
    exceptionShowListing: [""],
    registrasiAwal: "13 December, 2023",
    expiredDateMember: "13 April, 2024",
    masaBerlangganan: "",
  },
];
