import React, { useState, useEffect } from "react";
import { listingData } from "../../data/listing";
import ProductViews from "../ProductViews";
import { dataMember } from "../../data/member";
import { Helmet } from "react-helmet";

function withDataListingPerumahan(OriginalComponent: any) {
  return (props: any) => {
    const [filterKategori, setFilterKategori] = useState(new Array());
    const [filterTipe, setFilterTipe] = useState(new Array());
    const [filterStock, setFilterStock] = useState(new Array());
    const [filterLT, setFilterLT] = useState(new Array());
    const [filterLB, setFilterLB] = useState(new Array());
    const [filterRuanganKT, setFilterRuanganKT] = useState(new Array());
    const [filterRuanganKM, setFilterRuanganKM] = useState(new Array());
    const [filterKotaKab, setFilterKotaKab] = useState(new Array());
    // const [valMinHargaShow, setValMinHargaShow] = useState(new Array());
    // const [valMaxHargaShow, setValMaxHargaShow] = useState(new Array());

    // const valMinHargaShow;

    let valMinHargaShow = new Array();
    listingData.map((val: any, key: any) => {
      if (!valMinHargaShow.includes(listingData[key].harga)) {
        valMinHargaShow.push(listingData[key].harga);
        return valMinHargaShow;
      }
    });
    let MinHargaShow = Math.min.apply(null, valMinHargaShow);

    let valMaxHargaShow = new Array();
    listingData.map((val: any, key: any) => {
      if (!valMaxHargaShow.includes(listingData[key].harga)) {
        valMaxHargaShow.push(listingData[key].harga);
        return valMaxHargaShow;
      }
    });
    let MaxHargaShow = Math.max.apply(null, valMaxHargaShow);

    const [pricerangeMin, setPriceRangeMin] = useState(0);
    const [pricerangeMax, setPriceRangeMax] = useState(0);

    const [dataResult, setDataResult] = useState(new Object());

    // hargamin: any,
    //   hargamax: any,
    //   ruangKM: any,
    //   ruangKT: any,
    //   stok: any,
    //   kota: any,
    //   lt: any,
    //   lb: any,
    //   tipe: any,
    //   kategori: any

    React.useEffect(() => {
      setDataResult(
        FilterDataHook(
          pricerangeMin,
          pricerangeMax,
          filterRuanganKM,
          filterRuanganKT,
          filterStock,
          filterKotaKab,
          filterLT,
          filterLB,
          filterTipe,
          filterKategori
        )
      );
    }, [
      pricerangeMin,
      pricerangeMax,
      filterRuanganKM,
      filterRuanganKT,
      filterStock,
      filterKotaKab,
      filterLT,
      filterLB,
      filterTipe,
      filterKategori,
    ]);

    function handleChangeKategori(e: any) {
      if (!filterKategori.includes(e.target.value)) {
        setFilterKategori([...filterKategori, e.target.value]);
      } else if (filterKategori.includes(e.target.value)) {
        setFilterKategori(
          filterKategori.filter((filterKategori) => {
            return !filterKategori.includes(e.target.value);
          })
        );
      }
    }

    function handleChangeTipe(e: any) {
      if (!filterTipe.includes(e.target.value)) {
        setFilterTipe([...filterTipe, e.target.value]);
      } else if (filterTipe.includes(e.target.value)) {
        setFilterTipe(
          filterTipe.filter((filterTipe) => {
            return !filterTipe.includes(e.target.value);
          })
        );
      }
    }

    function handleChangeStock(e: any) {
      if (!filterStock.includes(e.target.value)) {
        setFilterStock([...filterStock, e.target.value]);
      } else if (filterStock.includes(e.target.value)) {
        setFilterStock(
          filterStock.filter((filterStock) => {
            return !filterStock.includes(e.target.value);
          })
        );
      }
    }

    function handleChangeLT(e: any) {
      if (!filterLT.includes(e.target.value)) {
        setFilterLT([...filterLT, e.target.value]);
      } else if (filterLT.includes(e.target.value)) {
        setFilterLT(
          filterLT.filter((filterLT) => {
            return !filterLT.includes(e.target.value);
          })
        );
      }
    }

    function handleChangeLB(e: any) {
      if (!filterLB.includes(e.target.value)) {
        setFilterLB([...filterLB, e.target.value]);
      } else if (filterLB.includes(e.target.value)) {
        setFilterLB(
          filterLB.filter((filterLB) => {
            return !filterLB.includes(e.target.value);
          })
        );
      }
    }

    function handleChangeRuanganKT(e: any) {
      if (!filterRuanganKT.includes(e.target.value)) {
        setFilterRuanganKT([...filterRuanganKT, e.target.value]);
      } else if (filterRuanganKT.includes(e.target.value)) {
        setFilterRuanganKT(
          filterRuanganKT.filter((filterRuanganKT) => {
            return !filterRuanganKT.includes(e.target.value);
          })
        );
      }
    }

    function handleChangeRuanganKM(e: any) {
      if (!filterRuanganKM.includes(e.target.value)) {
        setFilterRuanganKM([...filterRuanganKM, e.target.value]);
      } else if (filterRuanganKM.includes(e.target.value)) {
        setFilterRuanganKM(
          filterRuanganKM.filter((filterRuanganKM) => {
            return !filterRuanganKM.includes(e.target.value);
          })
        );
      }
    }

    function handleChangeKotaKab(e: any) {
      if (!filterKotaKab.includes(e.target.value)) {
        setFilterKotaKab([...filterKotaKab, e.target.value]);
      } else if (filterKotaKab.includes(e.target.value)) {
        setFilterKotaKab(
          filterKotaKab.filter((filterKotaKab) => {
            return !filterKotaKab.includes(e.target.value);
          })
        );
      }
    }

    // function handleChangeHargaMin(e: any) {
    //   setPriceRangeMin(e.target.value);
    // }

    function handleChangeHargaMin(e: any) {
      if (pricerangeMin !== 0 && pricerangeMax < pricerangeMin) {
        setPriceRangeMin(e.target.value);
        setPriceRangeMax(e.target.value);
      } else {
        setPriceRangeMin(e.target.value);
      }
    }

    // function handleChangeHargaMax(e: any) {
    //   setPriceRangeMax(e.target.value);
    // }

    function handleChangeHargaMax(e: any) {
      setPriceRangeMax(e.target.value);
      if (pricerangeMin > pricerangeMax) {
        setPriceRangeMin(e.target.value);
        setPriceRangeMax(e.target.value);
      } else {
        setPriceRangeMax(e.target.value);
      }
    }

    function FilterDataHook(
      hargamin: any,
      hargamax: any,
      ruangKM: any,
      ruangKT: any,
      stok: any,
      kota: any,
      lt: any,
      lb: any,
      tipe: any,
      kategori: any
    ) {
      let dataFiltered = [...listingData];
      dataFiltered = dataFiltered.sort(() => Math.random() - Math.random());
      dataFiltered = dataFiltered.sort(() => Math.random() - Math.random());
      // filterKategori;

      let hargaminData = hargamin;
      let hargamaxData = hargamax;
      let ruangKMData;
      let ruangKTData;
      let stokData;
      let kotaData;
      let ltData;
      let lbData;
      let tipeData;
      let kategoriData;

      let dataSementara = new Array();
      // hargaminData = hargamin;
      // hargamaxData = hargamax;
      Object.keys(dataFiltered).map((val, key) => {
        ruangKMData = ruangKM.includes(dataFiltered[key].kamarMandi);
        ruangKTData = ruangKT.includes(dataFiltered[key].kamarTidur);
        stokData = stok.includes(dataFiltered[key].stock);
        kotaData = kota.includes(dataFiltered[key].kotaKab);
        ltData = lt.includes(dataFiltered[key].luasTanah);
        lbData = lb.includes(dataFiltered[key].luasBangunan);
        tipeData = tipe.includes(dataFiltered[key].tipe);
        kategoriData = kategori.includes(dataFiltered[key].kategori);
        // console.log("data id : ", dataFiltered[key].id);

        if (kota.length === 0 && hargaminData === 0 && hargamaxData === 0) {
          return dataSementara.push(dataFiltered[key]);
        } else if (
          kotaData &&
          dataFiltered[key].harga >= hargaminData &&
          dataFiltered[key].harga <= hargamaxData
        ) {
          return dataSementara.push(dataFiltered[key]);
        } else if (kotaData && hargaminData === 0 && hargamaxData === 0) {
          return dataSementara.push(dataFiltered[key]);
        } else if (
          kota.length === 0 &&
          dataFiltered[key].harga >= hargaminData &&
          dataFiltered[key].harga <= hargamaxData
        ) {
          return dataSementara.push(dataFiltered[key]);
        }
      });

      return dataSementara;
    }

    const HandleResetAll = () => {};

    // Select Data Random
    const [dataMemberRandom, setDataMemberRandom] = useState(new Array());

    Object.keys(dataMember)
      .sort(() => Math.random() - Math.random())
      .sort(() => Math.random() - Math.random())
      .map((val, key) => {
        if (!dataMemberRandom.includes(dataMember[key])) {
          setDataMemberRandom([...dataMemberRandom, dataMember[key]]);
        }
      });

    let memberFilter = new Array();

    Object.keys(dataMemberRandom).map((val, key) => {
      memberFilter.push(dataMemberRandom[key].id_member);
    });

    // Kontak Whatsapp
    let nomor = "";

    for (let i = 0; i < dataMember.length; i++) {
      if (
        window.location.pathname.split("/").includes(dataMember[i]["id_member"])
      ) {
        nomor = dataMember[i]["nomor"];
      }
    }

    const handleClikCall = (e: any) => {
      e.preventDefault();
      if (window.location.pathname.split("/").includes("partner")) {
        window.open(`tel:${props.nomorChatCallPartner}`);
      } else if (window.location.pathname.split("/").includes("detail-page")) {
        window.open(`tel:${member !== "" ? nomor : dataMemberRandom[0].nomor}`);
      } else {
        window.open(`tel:${nomor !== "" ? nomor : props.numberChatCall}`);
      }
    };

    const handleClikOpenLinkAffiliate = (e: any) => {
      e.preventDefault();
      if (window.location.pathname.split("/").includes("partner")) {
        window.open("http://" + window.location.host + "/" + props.memberID);
      }
    };

    let idArrayMember = 0;
    let member = "";
    let namaMember = "";
    let fotoMember = "";
    let nomorMember = "";
    let nomorDisplayMember = "";
    let expiredLinkMemberDate = "";
    let exceptionShowListing = new Array();
    let popularName = "";

    const randomDataMember = dataMember
      .sort(() => Math.random() - Math.random())
      .sort(() => Math.random() - Math.random());

    let n = 0;

    // for (let i = 0; i < randomDataMember.length; i++) {
    //   if (
    //     [window.location.pathname.split("/").pop()].includes(
    //       randomDataMember[i]["id_member"]
    //     )
    //   ) {
    //     member = randomDataMember[i]["id_member"];
    //     namaMember = randomDataMember[i]["nama"];
    //     fotoMember = randomDataMember[i]["foto"];
    //     nomorMember = randomDataMember[i]["nomor"];
    //     nomorDisplayMember = randomDataMember[i]["nomorDisplay"];
    //     expiredLinkMemberDate = randomDataMember[i]["expiredDateMember"];
    //     exceptionShowListing = randomDataMember[i]["exceptionShowListing"];
    //     popularName = randomDataMember[i]["namaPopuler"];
    //     idArrayMember = i;
    //     console.log("data n : ", n);
    //     n++;
    //   } else {
    //     member = randomDataMember[0]["id_member"];
    //     namaMember = randomDataMember[0]["nama"];
    //     fotoMember = randomDataMember[0]["foto"];
    //     nomorMember = randomDataMember[0]["nomor"];
    //     nomorDisplayMember = randomDataMember[0]["nomorDisplay"];
    //     expiredLinkMemberDate = randomDataMember[0]["expiredDateMember"];
    //     exceptionShowListing = randomDataMember[0]["exceptionShowListing"];
    //     popularName = randomDataMember[0]["namaPopuler"];
    //     idArrayMember = i;
    //     n++;
    //   }
    // }

    Object.keys(randomDataMember).map((val, key) => {
      if (
        [window.location.pathname.split("/").pop()].includes(
          randomDataMember[key]["id_member"]
        )
      ) {
        member = randomDataMember[key]["id_member"];
        namaMember = randomDataMember[key]["nama"];
        fotoMember = randomDataMember[key]["foto"];
        nomorMember = randomDataMember[key]["nomor"];
        nomorDisplayMember = randomDataMember[key]["nomorDisplay"];
        expiredLinkMemberDate = randomDataMember[key]["expiredDateMember"];
        exceptionShowListing = randomDataMember[key]["exceptionShowListing"];
        popularName = randomDataMember[key]["namaPopuler"];
        idArrayMember = key;
        console.log(
          "cetak map : ",
          [window.location.pathname.split("/").pop()].includes(
            randomDataMember[key]["id_member"]
          )
        );
      }
    });

    // Get Data
    let kompleksPerumahan = "";
    let kotakabupaten = "";
    let kec_kel = "";
    let price = 0;
    let id_product = "";
    let tagLine = "";
    let imageIcon = "";

    const cekUrl = new URLSearchParams(window.location.search).get("id");
    listingData.map((data: any, key: any) => {
      if (cekUrl === listingData[key].id.toString()) {
        kompleksPerumahan = listingData[key].namaKompleks;
        kotakabupaten = listingData[key].kotaKab;
        kec_kel = listingData[key].kelurahanOrKecamatan;
        price = listingData[key].harga;
        id_product = listingData[key].id.toString();
        tagLine = listingData[key].taglineHook;
        imageIcon = listingData[key].displayimage[0];
      }
    });

    const helmettry = () => {
      return (
        <>
          {/* <Helmet>
            <title>{kompleksPerumahan}</title>
            <meta name="description" content={tagLine} />
            <meta property="og:image" content={imageIcon} />
            <link rel="icon" href={imageIcon} />
            <link rel="apple-touch-icon" href={imageIcon} />
          </Helmet> */}

          <Helmet>
            <title>{kompleksPerumahan}</title>
            <meta
              name="viewport"
              content="initial-scale=1.0, width=device-width"
            />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="description" key="description" content={tagLine} />
            <meta name="title" key="title" content={kompleksPerumahan} />
            <meta
              property="og:title"
              key="og:title"
              content={kompleksPerumahan}
            />
            <meta property="og:locale" key="og:locale" content="en_US" />
            <meta charSet="utf-8" />
            <meta property="og:type" key="og:type" content="website" />
            <meta
              property="og:description"
              key="og:description"
              content={tagLine}
            />
            <meta
              property="og:image"
              key="og:image"
              content={`${process.env.BASE_URL}/logo2.png`}
            />
          </Helmet>
        </>
      );
    };

    function nFormatter(num: number, digits: number) {
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "Ribuan" },
        { value: 1e6, symbol: "Jutaan" },
        { value: 1e9, symbol: "Miliaran" },
        { value: 1e12, symbol: "Triliunan" },
        // { value: 1e15, symbol: "P" },
        // { value: 1e18, symbol: "E" },
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup
        .slice()
        .reverse()
        .find(function (item) {
          return num >= item.value;
        });

      return item
        ? (num / item.value).toFixed(digits).replace(rx, "$1") +
            "%20" +
            item.symbol
        : "0";
    }

    const shortHarga = nFormatter(props.harga, 1);
    const shortHargaDetail = nFormatter(price, 1);

    const handleClickChatDetailPage = (e: any) => {
      if (window.location.pathname.split("/").includes("detail-page")) {
        helmettry();
        e.preventDefault();
        window.open(
          `https://api.whatsapp.com/send/?phone=${
            member !== "" ? nomor : dataMemberRandom[0].nomor
          }&text=Halo%20kak,%20saya%20dapet%20info%20dari%20${
            window.location.href
          }%20mau%20konsultasi%20properti%20di%20:%0a%0aKec/Kel%20:%20${kec_kel}%0aKota%20:%20${kotakabupaten}%0aHarga%20:%20${shortHargaDetail}`
        ) ||
          window.location.replace(
            `https://api.whatsapp.com/send/?phone=${
              member !== "" ? nomor : dataMemberRandom[0].nomor
            }&text=Halo%20kak,%20saya%20dapet%20info%20dari%20${
              window.location.href
            }%20mau%20konsultasi%20properti%20di%20:%0a%0aKec/Kel%20:%20${kec_kel}%0aKota%20:%20${kotakabupaten}%0aHarga%20:%20${shortHargaDetail}`
          );
      } else if (window.location.pathname.split("/").includes("partner")) {
        e.preventDefault();
        helmettry();
        window.open(
          `https://api.whatsapp.com/send/?phone=${props.nomorChatCallPartner}&text=Halo%20kak,%20saya%20dapet%20info%20dari%20${window.location.href}%20mau%20konsultasi%20properti`
        ) ||
          window.location.replace(
            `https://api.whatsapp.com/send/?phone=${props.nomorChatCallPartner}&text=Halo%20kak,%20saya%20dapet%20info%20dari%20${window.location.href}%20mau%20konsultasi%20properti`
          );
      } else {
        e.preventDefault();
        helmettry();
        window.open(
          `https://api.whatsapp.com/send/?phone=${
            nomor !== "" ? nomor : "+6282245452288"
          }&text=Halo%20kak,%20saya%20dapet%20info%20dari%20${
            window.location.href
          }%20mau%20konsultasi%20properti`
        ) ||
          window.location.replace(
            `https://api.whatsapp.com/send/?phone=${
              nomor !== "" ? nomor : "+6282245452288"
            }&text=Halo%20kak,%20saya%20dapet%20info%20dari%20${
              window.location.href
            }%20mau%20konsultasi%20properti`
          );
      }
    };

    const handleClickChat = (e: any) => {
      e.preventDefault();
      helmettry();
      window.open(
        `https://api.whatsapp.com/send/?phone=${
          nomor !== "" ? nomor : props.numberChatCall
        }&text=Halo%20kak,%20saya%20dapet%20info%20dari%20${
          cekUrl !== ""
            ? "https://" +
              window.location.hostname +
              "/detail-page/" +
              member +
              "?id=" +
              props.id
            : window.location.href
        }%20mau%20konsultasi%20properti%20di%20:%0a%0aKec/Kel%20:%20${
          props.kelurahanOrKecamatan
        }%0aKota%20:%20${props.kotaKab}%0aHarga%20:%20${shortHarga}`
      ) ||
        window.location.replace(
          `https://api.whatsapp.com/send/?phone=${
            nomor !== "" ? nomor : props.numberChatCall
          }&text=Halo%20kak,%20saya%20dapet%20info%20dari%20${
            cekUrl === ""
              ? "https://" +
                window.location.hostname +
                "/detail-page/" +
                member +
                "?id=" +
                props.id
              : window.location.href
          }%20mau%20konsultasi%20properti%20di%20:%0a%0aKec/Kel%20:%20${
            props.kelurahanOrKecamatan
          }%0aKota%20:%20${props.kotaKab}%0aHarga%20:%20${shortHarga}`
        );
    };

    return (
      <OriginalComponent
        pricerangeMin={pricerangeMin}
        pricerangeMax={pricerangeMax}
        HandleResetAll={HandleResetAll}
        handleChangeKategori={handleChangeKategori}
        handleChangeHargaMax={handleChangeHargaMax}
        handleChangeHargaMin={handleChangeHargaMin}
        handleChangeKotaKab={handleChangeKotaKab}
        handleChangeRuanganKM={handleChangeRuanganKM}
        handleChangeRuanganKT={handleChangeRuanganKT}
        handleChangeLT={handleChangeLT}
        handleChangeLB={handleChangeLB}
        handleChangeStock={handleChangeStock}
        handleChangeTipe={handleChangeTipe}
        FilterDataHook={FilterDataHook}
        dataResult={dataResult}
        handleClickChat={handleClickChat}
        member={member}
        namaMember={namaMember}
        nomorMember={nomorMember}
        fotoMember={fotoMember}
        handleClikCall={handleClikCall}
        handleClickChatDetailPage={handleClickChatDetailPage}
        idArrayMember={idArrayMember}
        MinHargaShow={MinHargaShow}
        MaxHargaShow={MaxHargaShow}
        nomorDisplayMember={nomorDisplayMember}
        expiredLinkMemberDate={expiredLinkMemberDate}
        exceptionShowListing={exceptionShowListing}
        popularName={popularName}
        dataMemberRandom={dataMemberRandom}
        memberFilter={memberFilter}
        handleClikOpenLinkAffiliate={handleClikOpenLinkAffiliate}
        {...props}
      />
    );
  };
}

export default withDataListingPerumahan;
