import { listingData } from "../data/listing";
import "./style.css";
import React from "react";
import withDataListingPerumahan from "./utils/withDataListingPerumahan";
import { dataMember } from "../data/member";
import Parent, { Helmet } from "react-helmet";
import Child from "react-helmet";
import Font from "react-font";
import {
  BsFacebook,
  BsFillTelephoneFill,
  BsTwitter,
  BsWhatsapp,
} from "react-icons/bs";
import { RiTwitterXLine, RiWhatsappFill } from "react-icons/ri";
import { Link } from "react-router-dom";

function DetailPageData(props: any) {
  function nFormatter(num: number, digits: number) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "Ribu" },
      { value: 1e6, symbol: "Juta" },
      { value: 1e9, symbol: "Miliar" },
      { value: 1e12, symbol: "Triliun" },
      // { value: 1e15, symbol: "P" },
      // { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });

    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + " " + item.symbol
      : "0";
  }

  const url = new URLSearchParams(window.location.search).get("id");
  const detailData = listingData.map((data: any, key: any) => {
    if (url === listingData[key].id.toString()) {
      // Cek Luas Tanah
      const HandleCekLuasTanah = () => {
        if (
          listingData[key].luasTanah !== 0 &&
          listingData[key].luasTanah !== null
        ) {
          return (
            <div className="w-full grid grid-cols-[40%_60%] gap-2 text-sm md:text-lg">
              <h4>Luas Tanah</h4>
              <h5>
                : {listingData[key].luasTanah} m<sup>2</sup>
              </h5>
            </div>
          );
        }
      };
      const cekLuasTanah = HandleCekLuasTanah();

      // Cek Luas Bangunan
      const HandlecekLuasBangunan = () => {
        if (
          listingData[key].luasBangunan !== 0 &&
          listingData[key].luasBangunan !== null
        ) {
          return (
            <div className="w-full grid grid-cols-[40%_60%] gap-2 text-sm md:text-lg">
              <h4>Luas Bangunan</h4>
              <h5>
                : {listingData[key].luasBangunan} m<sup>2</sup>
              </h5>
            </div>
          );
        }
      };
      const cekLuasBangunan = HandlecekLuasBangunan();

      const HandlecekLantai = () => {
        if (listingData[key].lantai !== 0 && listingData[key].lantai !== null) {
          return (
            <div className="w-full grid grid-cols-[40%_60%] gap-2 text-sm md:text-lg">
              <h4>Lantai</h4>
              <h5>: {listingData[key].lantai} Lantai</h5>
            </div>
          );
        }
      };
      const cekLantai = HandlecekLantai();

      // Cek Kamar Tidur
      const HandleCekKamarTidur = () => {
        if (listingData[key].kamarTidur !== 0) {
          return (
            <div className="w-full grid grid-cols-[40%_60%] gap-2 text-sm md:text-lg">
              <h4>Kamar Tidur</h4>
              <h5>: {listingData[key].kamarTidur} KT</h5>
            </div>
          );
        }
      };
      const cekKamarTidur = HandleCekKamarTidur();

      // Cek Kamar Mandi
      const HandleCekKamarMandi = () => {
        if (listingData[key].kamarMandi !== 0) {
          return (
            <div className="w-full grid grid-cols-[40%_60%] gap-2 text-sm md:text-lg">
              <h4>Kamar Mandi</h4>
              <h5>: {listingData[key].kamarMandi} KT</h5>
            </div>
          );
        }
      };
      const cekKamarMandi = HandleCekKamarMandi();

      // Cek Kamar Pengembang
      const HandleCekPengembang = () => {
        if (
          listingData[key].developer !== "-" &&
          listingData[key].developer !== ""
        ) {
          return (
            <div className="w-full grid grid-cols-[40%_60%] gap-2 text-sm md:text-lg">
              <h4>Pengembang</h4>
              <h5>: {listingData[key].developer}</h5>
            </div>
          );
        }
      };
      const cekPengembang = HandleCekPengembang();

      // Cek Cara Kredit
      const HandleCekCaraKredit = () => {
        if (
          listingData[key].metodePembayaran === "Cash/Kredit" ||
          listingData[key].metodePembayaran === "Kredit"
        ) {
          return (
            <div className="w-full grid grid-cols-[40%_60%] gap-2 text-sm md:text-lg">
              <h4>Kredit</h4>
              <h5>: {listingData[key].caraKredit}</h5>
            </div>
          );
        }
      };
      const cekCaraKredit = HandleCekCaraKredit();

      // Cek DP
      const HandleCekDP = () => {
        if (
          listingData[key].metodePembayaran === "Cash/Kredit" ||
          listingData[key].metodePembayaran === "Kredit"
        ) {
          if (listingData[key].dpProsentase === "yes") {
            return (
              <div className="w-full grid grid-cols-[40%_60%] gap-2 text-sm md:text-lg">
                <h4>DP</h4>
                <h5>: {listingData[key].dp} %</h5>
              </div>
            );
          } else {
            return (
              <div className="w-full grid grid-cols-[40%_60%] gap-2 text-sm md:text-lg">
                <h4>DP</h4>
                <h5>
                  : Rp{" "}
                  {new Intl.NumberFormat("en-ID", {})
                    .format(listingData[key].dp)
                    .replace(/,/g, ".")}
                </h5>
              </div>
            );
          }
        }
      };
      const cekDP = HandleCekDP();

      // Cek Video Review
      const VideoReview = () => {
        if (
          listingData[key].videoReview !== "" &&
          listingData[key].videoReview !== "-"
        ) {
          return (
            <>
              <section className="border-t-2 border-t-violet-400">
                <div className="w-full my-2 ">
                  <div className="w-full px-3 flex flex-col ">
                    <h1 className="font-bold my-3 text-b">Video Review :</h1>

                    <iframe
                      src={listingData[key].videoReview}
                      title="Perumahan Sidoarjo Sukodono"
                      className="self-center w-full h-[40vh] md:h-[60vh] bg-slate-300"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </section>
            </>
          );
        }
      };

      const showVideoReview = VideoReview();

      const shortHarga = nFormatter(listingData[key].harga, 1);

      const htmlDeskripsi = listingData[key].Deksripsi;

      return (
        <>
          {/* <Helmet>
            <title>{listingData[key].namaKompleks}</title>

            <meta name="description" content={listingData[key].taglineHook} />
            <link rel="icon" href={`${listingData[key].displayimage[0]}`} />
            <link
              rel="apple-touch-icon"
              href={`${listingData[key].displayimage[0]}`}
            />
          </Helmet> */}

          <Helmet>
            <title>{listingData[key].namaKompleks}</title>
            <meta
              name="viewport"
              content="initial-scale=1.0, width=device-width"
            />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta
              name="description"
              key="description"
              content={listingData[key].taglineHook}
            />
            <meta
              name="title"
              key="title"
              content={listingData[key].namaKompleks}
            />
            <meta
              property="og:title"
              key="og:title"
              content={listingData[key].namaKompleks}
            />
            <meta property="og:locale" key="og:locale" content="en_US" />
            <meta charSet="utf-8" />
            <meta property="og:type" key="og:type" content="website" />
            <meta
              property="og:description"
              key="og:description"
              content={listingData[key].taglineHook}
            />
            <meta
              property="og:image"
              key="og:image"
              content={listingData[key].displayimage[0]}
            />
          </Helmet>
          {/* price Tagline */}
          <section>
            <div className="w-full my-2">
              <div className="w-full lg:w-10/12 px-3">
                {/* {listingData[key].hargaAwal !== 0 ? (
                  <>
                    <s className="text-red-500">
                      Rp{" "}
                      {new Intl.NumberFormat("en-ID", {})
                        .format(listingData[key].hargaAwal)
                        .replace(/,/g, ".")}
                    </s>
                  </>
                ) : (
                  <>
                    <div className="invisible">-</div>
                  </>
                )} */}
                {listingData[key].program !== "-" &&
                listingData[key].program !== "" ? (
                  <div className="rounded-lg w-fit px-3 border border-orange-600 text-orange-600">
                    {listingData[key].program}
                  </div>
                ) : (
                  <></>
                )}
                <p className="text-lg md:text-3xl my-2 font-extrabold">
                  {listingData[key].taglineHook}
                </p>
                <div className="flex gap-3 items-end">
                  <h2 className="italic text-sm md:text-base">
                    Harga Mulai Dari :
                  </h2>

                  {/* <h1 className="text-2xl md:text-4xl font-bold">
                  Rp{" "}
                  {new Intl.NumberFormat("en-ID", {})
                    .format(listingData[key].harga)
                    .replace(/,/g, ".")}
                </h1> */}

                  <Font family="Roboto">
                    <h2 className="text-xl md:text-2xl text-red-500 font-bold">
                      Rp{" "}
                      {/* {new Intl.NumberFormat("en-ID", {})
                    .format(props.harga)
                    .replace(/,/g, ".")} */}
                      {shortHarga}
                    </h2>
                  </Font>
                </div>
              </div>
              <div className=" flex items-center pt-2 text-[12px] my-2 lg:text-sm md:hidden italic text-grey-500 px-3 border-t-2 border-t-violet-400">
                <div className="h-10 mr-3 aspect-square bg-white rounded-full w-fit overflow-hidden">
                  {props.member !== "" ? (
                    <>
                      <img src={props.fotoMember} alt={props.namaMember} />
                    </>
                  ) : (
                    <>
                      <img
                        src={props.fotoDisplayMarketing}
                        alt={props.namaMarketing}
                      />
                    </>
                  )}
                </div>
                Marketing : <br />
                {`${
                  props.member !== ""
                    ? props.namaMember + " ( " + props.nomorDisplayMember + " )"
                    : props.namaMarketing +
                      " ( " +
                      props.nomorDisplayMarketing +
                      " )"
                }`}
              </div>
            </div>
          </section>
          {/* Specification */}
          <section className="border-t-2 border-t-violet-400 ">
            <div className="w-full my-2">
              <div className="w-full lg:w-10/12 px-3">
                <h1 className="font-bold my-3 text-b">Detail Spesifikasi</h1>
                <div className="w-full grid grid-cols-[40%_60%] gap-2 text-sm md:text-lg">
                  <h4>Tipe</h4>
                  <h5>: {listingData[key].tipe}</h5>
                </div>
                <div className="w-full grid grid-cols-[40%_60%] gap-2 text-sm md:text-lg">
                  <h4>Kategori</h4>
                  <h5>: {listingData[key].kategori}</h5>
                </div>

                <>{cekLuasTanah}</>
                <>{cekLuasBangunan}</>
                <>{cekLantai}</>
                <>{cekKamarTidur}</>
                <>{cekKamarMandi}</>

                <div className="w-full grid grid-cols-[40%_60%] gap-2 text-sm md:text-lg">
                  <h4>Stock</h4>
                  <h5>: {listingData[key].stock}</h5>
                </div>
                <div className="w-full grid grid-cols-[40%_60%] gap-2 text-sm md:text-lg">
                  <h4>Metode Pembayaran</h4>
                  <h5>: {listingData[key].metodePembayaran}</h5>
                </div>
                <div className="w-full grid grid-cols-[40%_60%] gap-2 text-sm md:text-lg">
                  <h4>UTJ</h4>
                  <h5>
                    :{" "}
                    {new Intl.NumberFormat("en-ID", {})
                      .format(listingData[key].utj)
                      .replace(/,/g, ".")}
                  </h5>
                </div>

                <>{cekCaraKredit}</>
                <>{cekDP}</>

                <div className="w-full grid grid-cols-[40%_60%] gap-2 text-sm md:text-lg">
                  <h4>Sertifikat</h4>
                  <h5>: {listingData[key].sertifikat}</h5>
                </div>

                <div className="w-full grid grid-cols-[40%_60%] gap-2 text-sm md:text-lg">
                  <h4>Lokasi</h4>
                  <h5>: {listingData[key].kelurahanOrKecamatan}</h5>
                </div>
                <div className="w-full grid grid-cols-[40%_60%] gap-2 text-sm md:text-lg">
                  <h4>Kota / Kabupaten</h4>
                  <h5>: {listingData[key].kotaKab}</h5>
                </div>
                {/* <div className="w-full grid grid-cols-[40%_60%] gap-2 text-sm md:text-lg">
                  <h4>Nama Komplek</h4>
                  <h5>: {listingData[key].namaKompleks}</h5>
                </div> */}

                <>{cekPengembang}</>
              </div>
            </div>
          </section>
          {/* Deskripsi */}
          <section className="border-t-2 border-t-violet-400">
            <div className="w-full my-2">
              <div className="w-full lg:w-10/12 px-3">
                <h1 className="font-bold my-3 text-b">Deskripsi :</h1>

                {/* <p className="whitespace-pre-line">
                  {listingData[key].Deksripsi}
                </p> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: listingData[key].Deksripsi,
                  }}
                  className="deskripsi-detail"
                ></div>
              </div>
            </div>
          </section>
          {/* VideoReview Variabel Test */}

          <div className="w-full flex justify-around py-5">
            <div
              className="border cursor-pointer border-black rounded-2xl px-4 py-1 flex justify-around items-center gap-3"
              onClick={props.handleClikCall}
            >
              <BsFillTelephoneFill />
              <h1 className="">Telepon</h1>
            </div>
            <div
              className="border  cursor-pointer bg-green-600 text-white rounded-2xl px-4 py-1 flex justify-around items-center gap-3"
              onClick={props.handleClickChatDetailPage}
            >
              <BsWhatsapp />
              <h1 className="">Chat</h1>
            </div>
          </div>

          <div className="my-10 mx-5 text-xl flex justify-start items-center gap-3">
            share :
            <Link
              to={`whatsapp://send?text=${window.location.href}`}
              data-action="share/whatsapp/share"
            >
              <RiWhatsappFill className="text-green-600 text-6xl" />
            </Link>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
              target="_blank"
            >
              <BsFacebook className="text-blue-800 text-5xl" />
            </a>
            <a
              href={`http://twitter.com/intent/tweet?${window.location.href}`}
              target="_blank"
            >
              <RiTwitterXLine className="text-white text-5xl bg-black rounded-full px-1 py-1" />
            </a>
          </div>

          {showVideoReview}
          {/* Video Review */}
          {/* <section className="border-t-2 border-t-violet-400">
            <div className="w-full my-2 ">
              <div className="w-full px-3 flex flex-col ">
                <h1 className="font-bold my-3 text-b">Video Review :</h1>

                <iframe
                  src={listingData[key].videoReview}
                  title="Perumahan Sidoarjo Sukodono"
                  className="self-center w-full h-fit md:h-[60vh] bg-slate-300"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </section> */}
        </>
      );
    }
  });

  return (
    <>
      {/* Cek Link Expired */}
      {detailData}
      {/* {new Date(props.expiredLinkMemberDate).valueOf() >=
      new Date().valueOf() ? (
        detailData
      ) : (
        <></>
      )} */}
    </>
  );
}

export default withDataListingPerumahan(DetailPageData);
