import React from "react";
import {
  BsCheck2Circle,
  BsFillTelephoneFill,
  BsWhatsapp,
} from "react-icons/bs";
import withDataListingPerumahan from "./utils/withDataListingPerumahan";
import { dataMember } from "../data/member";
import { Helmet } from "react-helmet";

function ContactUs(prop: any) {
  return (
    <>
      <div className={` px-3 py-3 ${prop.className}`}>
        <div className="shadow-lg px-2 py-10 bg-[#080a3f] rounded-3xl text-white flex flex-col justify-center items-center">
          <h1 className="font-bold">Hubungi Kami :</h1>
          <div className="h-40 aspect-square bg-white rounded-full my-5 w-fit overflow-hidden">
            {prop.member !== "" ? (
              <>
                <img src={prop.fotoMember} alt={prop.namaMember} />
              </>
            ) : (
              <>
                <img
                  src={prop.dataMemberRandom[0].foto}
                  alt={prop.dataMemberRandom[0].nama}
                />
              </>
            )}
          </div>
          <h1>
            {prop.namaMember !== ""
              ? prop.namaMember
              : prop.dataMemberRandom[0].nama}
          </h1>
          <h1 className="mb-5">Konsultan Properti</h1>
          <div className="w-full flex justify-around text-white">
            <div
              className="border border-white cursor-pointer rounded-2xl px-4 py-1 flex justify-around items-center gap-3"
              onClick={prop.handleClikCall}
            >
              <BsFillTelephoneFill />
              <h1 className="">Telepon</h1>
            </div>
            <div
              className="border border-white rounded-2xl cursor-pointer px-4 py-1 flex justify-around items-center gap-3"
              onClick={prop.handleClickChatDetailPage}
            >
              <BsWhatsapp />
              <h1 className="">Chat</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withDataListingPerumahan(ContactUs);
