import React from "react";
import { Text } from "react-font";
import { Link } from "react-router-dom";
import { dataMember } from "../data/member";

// interface Props {
//   color: string;
// }

function Logo(prop: { className?: String; mitra: string }) {
  let member = "";
  for (let i = 0; i < dataMember.length; i++) {
    if (
      window.location.pathname.split("/").includes(dataMember[i]["id_member"])
    ) {
      member = dataMember[i]["id_member"];
    }
  }
  return (
    <Link to={`${member !== "" ? "/" + member : "/"}`}>
      <h1 className={`font-extrabold ${prop.className} text-white`}>
        <Text family="Montserrat">RUMAH PRIMER</Text>
      </h1>
      <p className="italic text-[12px]">
        Partner with: <b>{prop.mitra}</b>
      </p>
    </Link>
  );
}

export default Logo;
