import React, { useState, useEffect } from "react";

import NavMenuHorizontal from "../../components/NavMenuHorizontal";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import {
  BsCheck2Circle,
  BsFillTelephoneFill,
  BsWhatsapp,
} from "react-icons/bs";
import "./landingpage.css";
import ProductViews from "../../components/ProductViews";
import { listingData } from "../../data/listing";
import HeaderMenu from "../../components/HeaderMenu";
import ImageSlideComponent from "../../components/ImageSlide";
import DetailPageData from "../../components/DetailPageData";
import Footer from "../../components/Footer";
import ContactUs from "../../components/ContactUs";
import RecommendProducts from "../../components/RecommendProducts";
import withDataListingPerumahan from "../../components/utils/withDataListingPerumahan";

function DetailPage(prop: any) {
  return (
    <>
      {/* HeaderMenu */}
      <HeaderMenu mitra={prop.popularName} />

      {/* line Style */}
      <div className="h-1  bg-lime-300 w-full md:mb-10"></div>

      <div className=" min-h-[100vh] lg:h-fit w-full grid justify-items-center">
        <div className="relative w-full h-fit lg:w-10/12 md:grid grid-cols-[70%_30%] overflow-scroll lg:overflow-visible">
          <div className="w-full">
            {/* Button */}
            <section className="w-full z-10 fixed bottom-0 bg-[#080a3f] md:hidden">
              <div className="w-full">
                {/* line Style */}
                <div className="h-1  bg-lime-300 w-full"></div>
                <div className="w-full flex justify-around text-white py-5">
                  <div
                    className="border border-white rounded-2xl px-4 py-1 flex justify-around items-center gap-3"
                    onClick={prop.handleClickChatDetailPage}
                  >
                    <BsWhatsapp />
                    <h1 className="">Chat</h1>
                  </div>
                  <div
                    className="border border-white rounded-2xl px-4 py-1 flex justify-around items-center gap-3"
                    onClick={prop.handleClikCall}
                  >
                    <BsFillTelephoneFill />
                    <h1 className="">Telepon</h1>
                  </div>
                </div>
              </div>
            </section>

            {/* Image */}
            <ImageSlideComponent />
            {/* {new Date(prop.expiredLinkMemberDate).valueOf() >=
            new Date().valueOf() ? (
              <ImageSlideComponent />
            ) : (
              <>
                <h1 className="text-xl">Data Tidak Tersedia</h1>
              </>
            )} */}

            {/* Spesifikasi */}
            <DetailPageData
              namaMarketing={prop.dataMemberRandom[0].nama}
              nomorDisplayMarketing={prop.dataMemberRandom[0].nomorDisplay}
              fotoDisplayMarketing={prop.dataMemberRandom[0].foto}
            />
            {/* {new Date(prop.expiredLinkMemberDate).valueOf() >=
            new Date().valueOf() ? (
              <DetailPageData />
            ) : (
              <></>
            )} */}
          </div>
          <div>
            {/* {new Date(prop.expiredLinkMemberDate).valueOf() >=
            new Date().valueOf() ? (
              <ContactUs className="hidden md:block " />
            ) : (
              <>
                <h1 className="text-xl">Data Tidak Tersedia</h1>
              </>
            )} */}

            <ContactUs className="hidden md:block " />

            {/* Rekomendasi */}
            <section className="border-t-2 md:border-t-0 border-t-violet-400 bg-slate-200 rounded-xl">
              <div className="w-full my-2">
                <div className="w-full px-3 flex flex-col gap-3">
                  <h1 className="font-bold my-3 text-xl">
                    Rekomendasi Perumahan Lainnya :
                  </h1>

                  {/* {new Date(prop.expiredLinkMemberDate).valueOf() >=
                  new Date().valueOf() ? (
                    <RecommendProducts idArrayMember={prop.idArrayMember} />
                  ) : (
                    <>
                      <h1 className="text-xl">Data Tidak Tersedia</h1>
                    </>
                  )} */}

                  <RecommendProducts idArrayMember={prop.idArrayMember} />
                </div>
              </div>
            </section>
          </div>
          {/* Close Block */}
          <section className="w-full  md:hidden">
            <div className="w-full">
              <div className="w-full h-20 flex justify-around text-white py-5"></div>
            </div>
          </section>
        </div>
      </div>
      <Footer className="h-14 hidden md:block" />
    </>
  );
}

export default withDataListingPerumahan(DetailPage);
