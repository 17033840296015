import React, { useState } from "react";
import NavMenuHorizontal from "./NavMenuHorizontal";
import MobileMenu from "./MobileMenu";

function HeaderMenu(prop: any) {
  // Landingpage Display
  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }
  const [compLandingPage, setCompLandingPage] = useState(
    "-translate-y-[100%] absolute"
  );
  const [activeHamburgerMenu1, setActiveHamburgerMenu1] =
    useState("text-white");
  const toggleLandingPage = (e: any) => {
    e.preventDefault();
    if (compLandingPage.split(" ").includes("-translate-y-[100%]")) {
      setCompLandingPage("-translate-y-[0%] fade-down-menu block min-h-[30vh]");
      setActiveHamburgerMenu1("text-violet-400");
    } else {
      setActiveHamburgerMenu1("text-white");
      setCompLandingPage("-translate-y-[100%] fade-up-menu");
      timeout(2000);
      setCompLandingPage("-translate-y-[100%] absolute");
    }
  };
  return (
    <>
      <header className="w-full">
        <div className="flex flex-col items-center justify-center w-full bg-[#080a3f] text-white">
          <nav className="w-full opacity-100 flex items-center z-10 bg-[#080a3f]">
            <NavMenuHorizontal
              onClick={toggleLandingPage}
              className={activeHamburgerMenu1}
              mitra={prop.mitra}
            />
          </nav>
          <div
            className={`w-screen flex justify-center items-center ${compLandingPage}`}
          >
            <MobileMenu />
          </div>
        </div>
      </header>
    </>
  );
}

export default HeaderMenu;
