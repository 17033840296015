import React, { useEffect, useState } from "react";

// Icon - icon
import { RiMenuUnfoldLine } from "react-icons/ri";

// Component

import Footer from "../../components/Footer";
import ProductViews from "../../components/ProductViews";

// Data
import { listingData } from "../../data/listing";
import FilterListing1 from "../../components/FilterListing";
import NavMenuHorizontal from "../../components/NavMenuHorizontal";
import MobileMenu from "../../components/MobileMenu";
import withDataListingPerumahan from "../../components/utils/withDataListingPerumahan";
import ProdukViewComponentAll from "../../components/ProdukViewComponentAll";
import { Helmet } from "react-helmet";

function Housing(prop: any) {
  // setFilterHideMobile
  const [hideFilterCategory, setHideFilterCategory] = useState("-left-[100%]");
  const [colorActiveFilter, setColorActiveFilter] = useState("text-white");
  const handleClickHideFilter = (e: any) => {
    e.preventDefault();
    if (hideFilterCategory.split(" ").includes("-left-[100%]")) {
      setHideFilterCategory(
        "left-[0%]  fade-right-filter h-[100vh] overflow-scroll"
      );
      setColorActiveFilter("text-violet-400");
      window.scrollTo(0, 0);
    } else {
      setHideFilterCategory("-left-[100%]  fade-left-filter");
      setColorActiveFilter("text-white");
      window.scrollTo(0, 0);
    }
  };

  // Landingpage Display
  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const [landingPage, setLandingPage] = useState(
    "-translate-y-[100%] absolute"
  );
  const [activeHamburgerMenu, setActiveHamburgerMenu] = useState("text-white");
  const toggleLandingPage = (e: any) => {
    e.preventDefault();
    if (landingPage.split(" ").includes("-translate-y-[100%]")) {
      setLandingPage("-translate-y-[0%] fade-down-menu block min-h-[30vh]");
      setActiveHamburgerMenu("text-violet-400");
    } else {
      setActiveHamburgerMenu("text-white");
      setLandingPage("-translate-y-[100%] fade-up-menu");
      timeout(2000);
      setLandingPage("-translate-y-[100%] absolute");
    }
  };

  return (
    <>
      <Helmet>
        <title>RUMAH PRIMER - Home</title>
        <meta name="description" content="Menyediakan Listing Properti Home" />

        <meta property="og:url" content="https://ujicoba.ioinvites.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="RUMAH PRIMER - Home" />
        <meta
          property="og:description"
          content="Menyediakan Listing Properti Home"
        />
        <meta property="og:image" content="/logo2.png" />

        <meta name="twitter:card" content="/logo2.png" />
        <meta property="twitter:domain" content="ujicoba.ioinvites.com" />
        <meta property="twitter:url" content="https://ujicoba.ioinvites.com/" />
        <meta name="twitter:title" content="RUMAH PRIMER - Home" />
        <meta
          name="twitter:description"
          content="Menyediakan Listing Properti Home"
        />
        <meta name="twitter:image" content="/logo2.png" />
      </Helmet>
      <header className="w-full fixed z-50">
        <div className="flex flex-col items-center justify-center w-full  bg-[#080a3f] text-white">
          <nav className="w-full opacity-100 flex items-center z-10 bg-[#080a3f]">
            <RiMenuUnfoldLine
              className={`md:hidden mx-1 text-3xl cursor-pointer  ${colorActiveFilter}`}
              onClick={handleClickHideFilter}
            />
            <NavMenuHorizontal
              onClick={toggleLandingPage}
              className={activeHamburgerMenu}
              mitra={prop.popularName}
            />
          </nav>
          <div
            className={`w-screen flex justify-center items-center ${landingPage}`}
          >
            <MobileMenu />
          </div>
        </div>
        {/* line Style */}
        <div className="h-1 bg-lime-400 w-full"></div>
      </header>

      {/* Second Menu */}
      <header className="w-full">
        <div className="flex flex-col items-center justify-center w-full bg-[#080a3f] text-white">
          <nav className="w-full opacity-100 flex items-center z-10 bg-[#080a3f]">
            <RiMenuUnfoldLine
              className={`md:hidden mx-1 text-3xl cursor-pointer  ${colorActiveFilter}`}
              onClick={handleClickHideFilter}
            />
            <NavMenuHorizontal
              onClick={toggleLandingPage}
              className={activeHamburgerMenu}
              mitra={prop.namaMember}
            />
          </nav>
          <div
            className={`w-screen flex justify-center items-center ${landingPage}`}
          >
            <MobileMenu />
          </div>
        </div>
      </header>

      {/* line Style */}
      <div className="h-1 bg-lime-300 w-full"></div>
      {/* Main Product */}
      <section className="my-2 hidden">
        <div className="w-full min-h-screen flex justify-center">
          <div className="relative w-full lg:w-11/12 h-full grid grid-cols-1 md:grid-cols-[30%_70%] lg:grid-cols-[20%_80%] items-center justify-items-center">
            {/* filter */}
            {/* <div
              className={`absolute w-full h-full z-20 md:hidden ${hideFilterCategory}`}
            >
              <FilterListing
                className={``}
                onClick={handleClickHideFilter}
                handleCategory={prop.handleChangeKategori}
                handleTipe={prop.handleChangeTipe}
              />
            </div>
            <div className="hidden md:block w-full h-full">
              <FilterListing
                className=" hidden md:block md:w-[100%]"
                onClick={handleClickHideFilter}
                // HandleResetAll1={prop.HandleResetAll()}
              />
            </div> */}
            {/* produkViews */}
            <main
              className={`grid grid-cols-1 lg:grid-cols-4 gap-2 px-3 w-full`}
            >
              {listingData.map((val: any, key: any) => (
                <ProductViews
                  harga={listingData[key].harga}
                  kamarTidur={listingData[key].kamarTidur}
                  kotaKab={listingData[key].kotaKab}
                  displayimage={listingData[key].displayimage[0]}
                  kamarMandi={listingData[key].kamarMandi}
                  luasTanah={listingData[key].luasTanah}
                  luasBangunan={listingData[key].luasBangunan}
                  tipe={listingData[key].tipe}
                  taglineHook={listingData[key].taglineHook}
                  kelurahanOrKecamatan={listingData[key].kelurahanOrKecamatan}
                  id={listingData[key].id}
                  classNameGrid="grid grid-cols-[35%_65%] lg:grid-cols-1"
                />
              ))}
            </main>
          </div>
        </div>
      </section>
      <section className="my-2">
        <ProdukViewComponentAll
          hideFilterCategory={hideFilterCategory}
          onClick={handleClickHideFilter}
        />
      </section>
      {/* line Style */}
      <div className="h-1 bg-violet-400 w-full"></div>
      <Footer className="h-14" />
    </>
  );
}

export default withDataListingPerumahan(Housing);
