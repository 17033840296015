import React from "react";
import HeaderMenu from "../../components/HeaderMenu";
import ContactUs from "../../components/ContactUs";
import withDataListingPerumahan from "../../components/utils/withDataListingPerumahan";
import { Helmet } from "react-helmet";

function ContactUsPage(prop: any) {
  return (
    <>
      <Helmet>
        <title>RUMAH PRIMER - CONTACT US</title>
        <meta
          name="description"
          content="Menyediakan Listing Properti Contact us"
        />

        <meta
          property="og:url"
          content="https://ujicoba.ioinvites.com/contact-us/"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="RUMAH PRIMER - CONTACT US" />
        <meta
          property="og:description"
          content="Menyediakan Listing Properti Contact us"
        />
        <meta property="og:image" content="/media/fazza.jpeg" />

        <meta name="twitter:card" content="/media/fazza.jpeg" />
        <meta property="twitter:domain" content="ujicoba.ioinvites.com" />
        <meta
          property="twitter:url"
          content="https://ujicoba.ioinvites.com/contact-us/"
        />
        <meta name="twitter:title" content="RUMAH PRIMER - CONTACT US" />
        <meta
          name="twitter:description"
          content="Menyediakan Listing Properti Contact us"
        />
        <meta name="twitter:image" content="/media/fazza.jpeg" />
      </Helmet>
      <HeaderMenu mitra={prop.popularName} />
      <section className="">
        <div className="h-screen w-screen bg-slate-800 flex justify-center">
          <div className="h-screen w-full md:w-[50%]  ">
            <ContactUs className="block " />
          </div>
        </div>
      </section>
    </>
  );
}

export default withDataListingPerumahan(ContactUsPage);
