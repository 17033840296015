import React, { useEffect, useState } from "react";
import withDataListingPerumahan from "./utils/withDataListingPerumahan";
import ProductViews from "./ProductViews";
import { listingData } from "../data/listing";
import { HiOutlineFolderArrowDown } from "react-icons/hi2";
import { dataMember } from "../data/member";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function ProdukViewComponentAll(prop: any) {
  // set Kota Kabupaten
  const kabupaten_kota = new Array();
  listingData.map((val: any, key: any) => {
    if (!kabupaten_kota.includes(listingData[key].kotaKab)) {
      return kabupaten_kota.push(listingData[key].kotaKab);
    }
  });

  const room = [
    "1 Kamar Tidur",
    "2 Kamar Tidur",
    "3 Kamar Tidur",
    "4 Kamar Tidur",
    "1 Kamar Mandi",
    "2 Kamar Mandi",
    "Ruang Keluarga",
    "Dapur",
  ];

  const tipe = new Array();

  listingData.map((val: any, key: any) => {
    if (!tipe.includes(listingData[key].tipe)) {
      return tipe.push(listingData[key].tipe);
    }
  });

  const kategori = new Array();
  listingData.map((val: any, key: any) => {
    if (!kategori.includes(listingData[key].kategori)) {
      return kategori.push(listingData[key].kategori);
    }
  });

  const stok = new Array();
  listingData.map((val: any, key: any) => {
    if (!stok.includes(listingData[key].stock)) {
      return stok.push(listingData[key].stock);
    }
  });

  // Menu Filter

  const lt = new Array();
  listingData.map((val: any, key: any) => {
    if (!lt.includes(listingData[key].luasTanah)) {
      return lt.push(listingData[key].luasTanah);
    }
  });
  const ltrange = lt.map((item: any, index: any) => {
    if (item === 0) {
      return <></>;
    }
    return (
      <>
        <input
          type="checkbox"
          value={item}
          onChange={(e: any) => prop.handleChangeLT(e)}
        />
        <span className="mx-2">
          {" "}
          {item} {"m2"}
        </span>
        <br />
      </>
    );
  });

  const lb = new Array();
  listingData.map((val: any, key: any) => {
    if (!lb.includes(listingData[key].luasBangunan)) {
      return lb.push(listingData[key].luasBangunan);
    }
  });

  const lbrange = lb.map((item: any, index: any) => {
    if (item === 0) {
      return <></>;
    }
    return (
      <>
        <input type="checkbox" value={item} />
        <span className="mx-2">
          {" "}
          {item} {"m2"}
        </span>
        <br />
      </>
    );
  });

  // Stok Cek
  const [chekClickStok, setChekClickStok] = useState(false);
  const [clickStok, setClickStok] = useState("hidden");
  const chekClickStok1 = !chekClickStok;
  const handleChekClickStok = (e: any) => {
    e.preventDefault();
    if (chekClickStok1) {
      setClickStok("block ");
      setChekClickStok(true);
    } else {
      setClickStok("hidden");
      setChekClickStok(false);
    }
  };

  // kategori cek
  const [chekClickKategori, setChekClickKategori] = useState(false);
  const [clickKategori, setClickKategori] = useState("hidden");
  const chekClickKategori1 = !chekClickKategori;
  const handleChekClickKategori = (e: any) => {
    e.preventDefault();
    if (chekClickKategori1) {
      setClickKategori("block ");
      setChekClickKategori(true);
    } else {
      setClickKategori("hidden");
      setChekClickKategori(false);
    }
  };

  // tipe
  const [chekClickTipe, setChekClickTipe] = useState(false);
  const [clickTipe, setClickTipe] = useState("hidden");
  const chekClickTipe1 = !chekClickTipe;
  const handleChekClickTipe = (e: any) => {
    e.preventDefault();
    if (chekClickTipe1) {
      setClickTipe("block ");
      setChekClickTipe(true);
    } else {
      setClickTipe("hidden");
      setChekClickTipe(false);
    }
  };

  // tanahbangunan
  const [chekClickLuasTanahBangunan, setChekClickLuasTanahBangunan] =
    useState(false);
  const [clickLuasTanahBangunan, setClickLuasTanahBangunan] =
    useState("hidden");
  const chekClickLuasTanahBangunan1 = !chekClickLuasTanahBangunan;
  const handleChekClickLuasTanahBangunan = (e: any) => {
    e.preventDefault();
    if (chekClickLuasTanahBangunan1) {
      setClickLuasTanahBangunan("block ");
      setChekClickLuasTanahBangunan(true);
    } else {
      setClickLuasTanahBangunan("hidden");
      setChekClickLuasTanahBangunan(false);
    }
  };

  // klik ruangan
  const [chekClickRuangan, setChekClickRuangan] = useState(false);
  const [clickRuangan, setClickRuangan] = useState("hidden");
  const chekClickRuangan1 = !chekClickRuangan;
  const handleChekClickRuangan = (e: any) => {
    e.preventDefault();
    if (chekClickRuangan1) {
      setClickRuangan("block ");
      setChekClickRuangan(true);
    } else {
      setClickRuangan("hidden");
      setChekClickRuangan(false);
    }
  };

  // kota kabupaten
  const [chekClickKotaKabupaten, setChekClickKotaKabupaten] = useState(false);
  const [clickKotaKabupaten, setClickKotaKabupaten] = useState("hidden");
  const chekClickKotaKabupaten1 = !chekClickKotaKabupaten;
  const handleChekClickKotaKabupaten = (e: any) => {
    e.preventDefault();
    if (chekClickKotaKabupaten1) {
      setClickKotaKabupaten("block ");
      setChekClickKotaKabupaten(true);
    } else {
      setClickKotaKabupaten("hidden");
      setChekClickKotaKabupaten(false);
    }
  };

  // range harga cek
  const [chekClickRangeHarga, setChekClickRangeHarga] = useState(false);
  const [clickRangeHarga, setClickRangeHarga] = useState("hidden");
  const chekClickRangeHarga1 = !chekClickRangeHarga;
  const handleChekClickRangeHarga = (e: any) => {
    e.preventDefault();
    if (chekClickRangeHarga1) {
      setClickRangeHarga("block ");
      setChekClickRangeHarga(true);
    } else {
      setClickRangeHarga("hidden");
      setChekClickRangeHarga(false);
    }
  };

  // const [productCart, setProductCart] = useState(new Object());

  // let memberFilter = new Array();

  // const pushMemberFilter = Object.keys(prop.dataMemberRandom).map(
  //   (val, key) => {
  //     memberFilter.push(prop.dataMemberRandom[key].id_member);
  //   }
  // );

  const ProductDisplay = Object.keys(prop.dataResult)
    .sort(() => Math.random() - Math.random())
    .sort(() => Math.random() - Math.random())
    .map((val: any, key: any) => {
      if (
        !prop.memberFilter.includes(window.location.pathname.split("/").pop())
      ) {
        let check = true;
        let dataSementara = prop.dataMemberRandom
          .sort(() => Math.random() - Math.random())
          .sort(() => Math.random() - Math.random())[0];
        while (check) {
          if (
            new Date(dataSementara.expiredDateMember).valueOf() >=
            new Date().valueOf()
          ) {
            check = false;
          } else {
            dataSementara = prop.dataMemberRandom
              .sort(() => Math.random() - Math.random())
              .sort(() => Math.random() - Math.random())[0];
          }
        }

        if (
          !prop.dataMemberRandom[
            prop.idArrayMember
          ].exceptionShowListing.includes(prop.dataResult[key].perumahan)
        ) {
          return (
            <>
              <ProductViews
                harga={prop.dataResult[key].harga}
                kamarTidur={prop.dataResult[key].kamarTidur}
                kotaKab={prop.dataResult[key].kotaKab}
                displayimage={prop.dataResult[key].displayimage[0]}
                kamarMandi={prop.dataResult[key].kamarMandi}
                luasTanah={prop.dataResult[key].luasTanah}
                luasBangunan={prop.dataResult[key].luasBangunan}
                tipe={prop.dataResult[key].tipe}
                taglineHook={prop.dataResult[key].taglineHook}
                kelurahanOrKecamatan={prop.dataResult[key].kelurahanOrKecamatan}
                id={prop.dataResult[key].id}
                namaKompleks={prop.dataResult[key].namaKompleks}
                hargaAwal={prop.dataResult[key].hargaAwal}
                sertifikat={prop.dataResult[key].sertifikat}
                lantai={prop.dataResult[key].lantai}
                program={prop.dataResult[key].program}
                nameDisplay={dataSementara.nama}
                NumberDisplay={dataSementara.nomorDisplay}
                numberChatCall={dataSementara.nomor}
                fotoMarketing={dataSementara.foto}
                classNameGrid="grid grid-cols-1 lg:grid-cols-1"
              />
            </>
          );
        }
      } else if (
        !dataMember[prop.idArrayMember].exceptionShowListing.includes(
          prop.dataResult[key].perumahan
        )
      ) {
        return (
          <>
            <ProductViews
              harga={prop.dataResult[key].harga}
              kamarTidur={prop.dataResult[key].kamarTidur}
              kotaKab={prop.dataResult[key].kotaKab}
              displayimage={prop.dataResult[key].displayimage[0]}
              kamarMandi={prop.dataResult[key].kamarMandi}
              luasTanah={prop.dataResult[key].luasTanah}
              luasBangunan={prop.dataResult[key].luasBangunan}
              tipe={prop.dataResult[key].tipe}
              taglineHook={prop.dataResult[key].taglineHook}
              kelurahanOrKecamatan={prop.dataResult[key].kelurahanOrKecamatan}
              id={prop.dataResult[key].id}
              namaKompleks={prop.dataResult[key].namaKompleks}
              hargaAwal={prop.dataResult[key].hargaAwal}
              sertifikat={prop.dataResult[key].sertifikat}
              lantai={prop.dataResult[key].lantai}
              program={prop.dataResult[key].program}
              classNameGrid="grid grid-cols-1 lg:grid-cols-1"
            />
          </>
        );
      }
    });

  let DisplayProduct;
  if (prop.dataResult.length === 0) {
    DisplayProduct = (
      <>
        <h1 className="col-span-3 font-bold uppercase text-xl">
          Mohon Maaf Pilihan Anda Belum Tersedia
        </h1>
      </>
    );
  } else if (
    new Date(prop.expiredLinkMemberDate).valueOf() <= new Date().valueOf()
  ) {
    DisplayProduct = (
      <>
        <h1 className="col-span-3 font-bold uppercase text-xl">
          Mohon Maaf Data Tidak Tersedia
        </h1>
      </>
    );
  } else {
    // DisplayProduct = ProductDisplay;
    DisplayProduct = ProductDisplay;
  }

  return (
    <>
      <div className="w-full min-h-[100vh] flex justify-center ">
        <div className="relative w-full  min-h-[100vh] grid grid-cols-1 md:grid-cols-[30%_70%] lg:grid-cols-[20%_80%] items-start justify-items-center">
          {/* filter mobile*/}
          <div
            className={`absolute w-full h-full z-20 md:hidden ${prop.hideFilterCategory}`}
          >
            <div className="h-full">
              <div
                className={` z-10 h-full bg-[#080a3f] text-white md:border-2 border-lime-300 w-screen   ${prop.className} px-2 py-2`}
              >
                <h1 className="italic font-bold px-3 py-3 underline">
                  FILTER LANJUTAN
                </h1>

                {/* Kategori */}
                <div className="px-3 py-3 space-y-1 hidden">
                  <div
                    onClick={handleChekClickKategori}
                    className="border-b-2 border-b-violet-400 flex justify-between items-center cursor-pointer"
                  >
                    <h1>Kategori</h1> <HiOutlineFolderArrowDown />
                  </div>
                  <div className={`px-3 fade-in ${clickKategori}`}>
                    {kategori.map((data, i) => (
                      <div>
                        <input
                          type="checkbox"
                          onChange={prop.handleChangeKategori}
                          value={data}
                          name="kategori"
                        />{" "}
                        <span className="mx-2">{data}</span> <br />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Tipe */}
                <div className="px-3 py-3 space-y-1 hidden">
                  <div
                    onClick={handleChekClickTipe}
                    className="border-b-2 border-b-violet-400 flex justify-between items-center cursor-pointer"
                  >
                    <h1>Tipe</h1> <HiOutlineFolderArrowDown />
                  </div>
                  <div className={`px-3 fade-in ${clickTipe}`}>
                    {tipe.map((item: any, i: any) => (
                      <div>
                        <input
                          type="checkbox"
                          value={item}
                          onChange={prop.handleChangeTipe}
                        />{" "}
                        <span className="mx-2">{item}</span> <br />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Stok */}
                <div className="px-3 py-3 space-y-1 hidden">
                  <div
                    onClick={handleChekClickStok}
                    className="border-b-2 border-b-violet-400 flex justify-between items-center cursor-pointer"
                  >
                    <h1>Stok</h1> <HiOutlineFolderArrowDown />
                  </div>
                  <div className={`px-3 fade-in ${clickStok}`}>
                    {stok.map((item: any, i: any) => (
                      <div>
                        <input
                          type="checkbox"
                          value={item}
                          onChange={(e: any) => prop.handleChangeStock(e)}
                        />{" "}
                        <span className="mx-2">{item}</span> <br />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Luas Tanah Luas Bangunan */}
                <div className="px-3 py-3 space-y-1 hidden">
                  <div
                    onClick={handleChekClickLuasTanahBangunan}
                    className="border-b-2 border-b-violet-400 flex justify-between items-center cursor-pointer"
                  >
                    <h1>Luas Tanah & Luas Bangunan</h1>{" "}
                    <HiOutlineFolderArrowDown />
                  </div>
                  <div className={`px-3 fade-in ${clickLuasTanahBangunan}`}>
                    <div>
                      <h1>Luas Tanah : </h1>
                      <div className="px-3">{ltrange}</div>
                    </div>
                    <div>
                      <h1>Luas Bangunan : </h1>
                      <div className="px-3">{lbrange}</div>
                    </div>
                  </div>
                </div>

                {/* Ruangan */}
                <div className="px-3 py-3 space-y-1 hidden">
                  <div
                    onClick={handleChekClickRuangan}
                    className="border-b-2 border-b-violet-400 flex justify-between items-center cursor-pointer"
                  >
                    <h1>Pilihan Ruangan</h1> <HiOutlineFolderArrowDown />
                  </div>
                  <div className={`px-3 fade-in ${clickRuangan}`}>
                    {room.map((item: any) => (
                      <div>
                        <input
                          type="checkbox"
                          value={item}
                          onChange={(e: any) => prop.handleChangeRuangan(e)}
                        />{" "}
                        <span className="mx-2">{item}</span>
                        <br />
                      </div>
                    ))}
                  </div>
                </div>

                {/* KotaKabupaten */}
                <div className="px-3 py-3 space-y-1 ">
                  <div
                    onClick={handleChekClickKotaKabupaten}
                    className="border-b-2 border-b-violet-400 flex justify-between items-center cursor-pointer"
                  >
                    <h1>Kota / Kabuputan</h1> <HiOutlineFolderArrowDown />
                  </div>
                  <div className={`px-3 fade-in ${clickKotaKabupaten}`}>
                    {kabupaten_kota.map((item: any, i: any) => (
                      <div>
                        <input
                          type="checkbox"
                          value={item}
                          onChange={(e: any) => prop.handleChangeKotaKab(e)}
                        />{" "}
                        <span className="mx-2">{item}</span> <br />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Range harga */}
                <div className="px-3 py-3 space-y-1 ">
                  <div
                    onClick={handleChekClickRangeHarga}
                    className=" border-b-2 border-b-violet-400 flex justify-between items-center cursor-pointer"
                  >
                    <h1>Range Harga</h1> <HiOutlineFolderArrowDown />
                  </div>
                  <div className={`fade-in ${clickRangeHarga}`}>
                    <h1>Harga Min :</h1>
                    <h1 className="text-3xl">
                      {new Intl.NumberFormat("en-ID", {})
                        .format(prop.pricerangeMin)
                        .replace(/,/g, ".")}{" "}
                    </h1>

                    <div className="flex space-x-3">
                      <h4>
                        Rp{" "}
                        {new Intl.NumberFormat("en-ID", {})
                          .format(prop.MinHargaShow / 1000000)
                          .replace(/,/g, ".")}{" "}
                      </h4>
                      <input
                        type="range"
                        onChange={prop.handleChangeHargaMin}
                        min={prop.MinHargaShow}
                        max={prop.MaxHargaShow}
                        step={100000}
                        value={prop.pricerangeMin}
                        className=""
                      ></input>
                      <h4>
                        Rp{" "}
                        {new Intl.NumberFormat("en-ID", {})
                          .format(prop.MaxHargaShow / 1000000)
                          .replace(/,/g, ".")}{" "}
                      </h4>
                    </div>
                    <br />
                    <h1>Harga Max :</h1>
                    <h1 className="text-3xl">
                      {new Intl.NumberFormat("en-ID", {})
                        .format(prop.pricerangeMax)
                        .replace(/,/g, ".")}
                    </h1>

                    <div className="flex space-x-3">
                      <h4>
                        Rp{" "}
                        {new Intl.NumberFormat("en-ID", {})
                          .format(prop.MinHargaShow / 1000000)
                          .replace(/,/g, ".")}{" "}
                      </h4>
                      <input
                        type="range"
                        onChange={prop.handleChangeHargaMax}
                        min={prop.MinHargaShow}
                        max={prop.MaxHargaShow}
                        step={100000}
                        value={prop.pricerangeMax}
                        className=""
                      ></input>
                      <h4>
                        Rp{" "}
                        {new Intl.NumberFormat("en-ID", {})
                          .format(prop.MaxHargaShow / 1000000)
                          .replace(/,/g, ".")}{" "}
                      </h4>
                    </div>
                  </div>
                </div>

                {/* Button */}
                <div className="flex space-x-5  justify-around items-center my-5">
                  <button
                    className="border px-3 py-2 w-fit bg-slate-900 text-white"
                    onClick={prop.onClick}
                  >
                    Tutup Filter
                  </button>
                  {/* <button
                    className="border px-3 py-2 w-fit bg-slate-900 text-white hidden"
                    onClick={prop.HandleResetAll()}
                  >
                    Reset
                  </button>
                  <button
                    className="border px-3 py-2 w-fit bg-slate-900 text-white"
                    onClick={prop.onClick}
                  >
                    Simpan
                  </button> */}
                </div>

                {/* <h1 className="italic text-red-500">
                  Filter Sedang Dalam Proses Pengembangan
                </h1> */}

                {/* <div>
                  {Object.keys(prop.dataResult).map((val: any, key: any) => (
                    <>
                      | {prop.dataResult[key].id} -{" "}
                      {prop.dataResult[key].kategori} |{" "}
                    </>
                  ))}
                </div> */}
              </div>
            </div>
          </div>
          {/* Filter PC */}
          <div className="hidden md:block w-full h-full">
            <div className="h-full">
              <div
                className={` z-10 h-full  shadow-lg md:border bg-[#080a3f] text-white border-lime-300 w-screen rounded-2xl  hidden md:block md:w-[100%] px-2 py-2`}
              >
                <h1 className="italic font-bold px-3 py-3 underline">
                  FILTER LANJUTAN
                </h1>

                {/* Kategori */}
                <div className="px-3 py-3 space-y-1 hidden">
                  <div
                    onClick={handleChekClickKategori}
                    className="border-b-2 border-b-violet-400 flex justify-between items-center cursor-pointer"
                  >
                    <h1>Kategori</h1> <HiOutlineFolderArrowDown />
                  </div>
                  <div className={`px-3 fade-in ${clickKategori}`}>
                    {kategori.map((data, i) => (
                      <div>
                        <input
                          type="checkbox"
                          onChange={prop.handleChangeKategori}
                          value={data}
                          name="kategori"
                        />{" "}
                        <span className="mx-2">{data}</span> <br />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Tipe */}
                <div className="px-3 py-3 space-y-1 hidden">
                  <div
                    onClick={handleChekClickTipe}
                    className="border-b-2 border-b-violet-400 flex justify-between items-center cursor-pointer"
                  >
                    <h1>Tipe</h1> <HiOutlineFolderArrowDown />
                  </div>
                  <div className={`px-3 fade-in ${clickTipe}`}>
                    {tipe.map((item: any, i: any) => (
                      <div>
                        <input
                          type="checkbox"
                          value={item}
                          onChange={prop.handleChangeTipe}
                        />{" "}
                        <span className="mx-2">{item}</span> <br />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Stok */}
                <div className="px-3 py-3 space-y-1 hidden">
                  <div
                    onClick={handleChekClickStok}
                    className="border-b-2 border-b-violet-400 flex justify-between items-center cursor-pointer"
                  >
                    <h1>Stok</h1> <HiOutlineFolderArrowDown />
                  </div>
                  <div className={`px-3 fade-in ${clickStok}`}>
                    {stok.map((item: any, i: any) => (
                      <div>
                        <input
                          type="checkbox"
                          value={item}
                          onChange={(e: any) => prop.handleChangeStock(e)}
                        />{" "}
                        <span className="mx-2">{item}</span> <br />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Luas Tanah Luas Bangunan */}
                <div className="px-3 py-3 space-y-1 hidden">
                  <div
                    onClick={handleChekClickLuasTanahBangunan}
                    className="border-b-2 border-b-violet-400 flex justify-between items-center cursor-pointer"
                  >
                    <h1>Luas Tanah & Luas Bangunan</h1>{" "}
                    <HiOutlineFolderArrowDown />
                  </div>
                  <div className={`px-3 fade-in ${clickLuasTanahBangunan}`}>
                    <div>
                      <h1>Luas Tanah : </h1>
                      <div className="px-3">{ltrange}</div>
                    </div>
                    <div>
                      <h1>Luas Bangunan : </h1>
                      <div className="px-3">{lbrange}</div>
                    </div>
                  </div>
                </div>

                {/* Ruangan */}
                <div className="px-3 py-3 space-y-1 hidden">
                  <div
                    onClick={handleChekClickRuangan}
                    className="border-b-2 border-b-violet-400 flex justify-between items-center cursor-pointer"
                  >
                    <h1>Pilihan Ruangan</h1> <HiOutlineFolderArrowDown />
                  </div>
                  <div className={`px-3 fade-in ${clickRuangan}`}>
                    {room.map((item: any) => (
                      <div>
                        <input
                          type="checkbox"
                          value={item}
                          onChange={(e: any) => prop.handleChangeRuangan(e)}
                        />{" "}
                        <span className="mx-2">{item}</span>
                        <br />
                      </div>
                    ))}
                  </div>
                </div>

                {/* KotaKabupaten */}
                <div className="px-3 py-3 space-y-1">
                  <div
                    onClick={handleChekClickKotaKabupaten}
                    className="border-b-2 border-b-violet-400 flex justify-between items-center cursor-pointer"
                  >
                    <h1>Kota / Kabuputan</h1> <HiOutlineFolderArrowDown />
                  </div>
                  <div className={`px-3 fade-in ${clickKotaKabupaten}`}>
                    {kabupaten_kota.map((item: any, i: any) => (
                      <div>
                        <input
                          type="checkbox"
                          value={item}
                          onChange={(e: any) => prop.handleChangeKotaKab(e)}
                        />{" "}
                        <span className="mx-2">{item}</span> <br />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Range harga */}
                <div className="px-3 py-3 space-y-1">
                  <div
                    onClick={handleChekClickRangeHarga}
                    className=" border-b-2 border-b-violet-400 flex justify-between items-center cursor-pointer"
                  >
                    <h1>Range Harga</h1> <HiOutlineFolderArrowDown />
                  </div>
                  <div className={`fade-in ${clickRangeHarga}`}>
                    <h1>Harga Min :</h1>
                    <h1 className="text-3xl">
                      {new Intl.NumberFormat("en-ID", {})
                        .format(prop.pricerangeMin)
                        .replace(/,/g, ".")}
                    </h1>

                    <div className="flex space-x-3">
                      <h4>
                        Rp{" "}
                        {new Intl.NumberFormat("en-ID", {})
                          .format(prop.MinHargaShow / 1000000)
                          .replace(/,/g, ".")}{" "}
                      </h4>
                      <input
                        type="range"
                        onChange={prop.handleChangeHargaMin}
                        min={prop.MinHargaShow}
                        max={prop.MaxHargaShow}
                        step={100000}
                        value={prop.pricerangeMin}
                        className=""
                      ></input>
                      <h4>
                        Rp{" "}
                        {new Intl.NumberFormat("en-ID", {})
                          .format(prop.MaxHargaShow / 1000000)
                          .replace(/,/g, ".")}{" "}
                      </h4>
                    </div>
                    <br />
                    <h1>Harga Max :</h1>
                    <h1 className="text-3xl">
                      {new Intl.NumberFormat("en-ID", {})
                        .format(prop.pricerangeMax)
                        .replace(/,/g, ".")}
                    </h1>

                    <div className="flex space-x-3">
                      <h4>
                        Rp{" "}
                        {new Intl.NumberFormat("en-ID", {})
                          .format(prop.MinHargaShow / 1000000)
                          .replace(/,/g, ".")}{" "}
                      </h4>
                      <input
                        type="range"
                        onChange={prop.handleChangeHargaMax}
                        min={prop.MinHargaShow}
                        max={prop.MaxHargaShow}
                        step={100000}
                        value={prop.pricerangeMax}
                        className="w-full"
                      ></input>
                      <h4>
                        Rp{" "}
                        {new Intl.NumberFormat("en-ID", {})
                          .format(prop.MaxHargaShow / 1000000)
                          .replace(/,/g, ".")}{" "}
                      </h4>
                    </div>
                  </div>
                </div>

                {/* Button */}
                {/* <div className="flex space-x-5  justify-around items-center my-5">
                  <button
                    className="border px-3 py-2 w-fit bg-slate-900 text-white"
                    onClick={prop.onClick}
                  >
                    Batal
                  </button>
                  <button className="border px-3 py-2 w-fit bg-slate-900 text-white">
                    Reset
                  </button>
                  <button
                    className="border px-3 py-2 w-fit bg-slate-900 text-white"
                    onClick={prop.onClick}
                  >
                    Simpan
                  </button>
                </div> */}

                {/* <h1 className="italic text-red-500">
                  Filter Sedang Dalam Proses Pengembangan
                </h1> */}

                {/* <div>
                  {Object.keys(prop.dataResult).map((val, key) => (
                    <>
                      | {prop.dataResult[key].id} -{" "}
                      {prop.dataResult[key].kategori} |{" "}
                    </>
                  ))}
                </div> */}
              </div>
            </div>
            {/* <FilterListing
              className=" hidden md:block md:w-[100%]"
              onClick={handleClickHideFilter}
              
            /> */}
          </div>
          {/* produkViews */}
          <main
            className={`grid grid-cols-1 lg:grid-cols-3 gap-2 px-3 w-full max-h-[100vh] overflow-scroll`}
          >
            {DisplayProduct}
          </main>
        </div>
      </div>
    </>
  );
}

export default withDataListingPerumahan(ProdukViewComponentAll);
