import React from "react";
import { Link } from "react-router-dom";
import withDataListingPerumahan from "./utils/withDataListingPerumahan";
import { dataMember } from "./../data/member";

// icon - icon
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { MdOutlineBathtub } from "react-icons/md";
import { BsFillTelephoneFill, BsWhatsapp } from "react-icons/bs";
import { BiBed } from "react-icons/bi";
import Font, { Text } from "react-font";
import { FaStairs } from "react-icons/fa6";

function ProductViews(props: any) {
  function nFormatter(num: number, digits: number) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "Ribu" },
      { value: 1e6, symbol: "Juta" },
      { value: 1e9, symbol: "Miliar" },
      { value: 1e12, symbol: "Triliun" },
      // { value: 1e15, symbol: "P" },
      // { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });

    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + " " + item.symbol
      : "0";
  }

  const shortHarga = nFormatter(props.harga, 1);

  const handleClikCall = (e: any) => {
    e.preventDefault();

    // window.open(`tel:${nomorMember}`);
    window.open(
      `tel:${
        props.memberFilter.includes(window.location.pathname.split("/").pop())
          ? props.nomorMember
          : props.numberChatCall
      }`
    );
  };

  return (
    <>
      <div className="">
        <div className="bg-white shadow-md h-full flex flex-col justify-between border rounded-3xl overflow-hidden">
          <Link
            reloadDocument={true}
            to={`${
              // props.member !==
              ![window.location.pathname.split("/").pop()].includes(
                props.member
              )
                ? "/detail-page/"
                : "/detail-page/" + props.member
            }?id=${props.id}`}
          >
            <div
              className={` ${props.classNameGrid} cursor-pointer items-center`}
            >
              <div className="relative  lg:border-b-2  lg:border-b-lime-300 w-full h-56 md:h-72 overflow-hidden  flex items-center ">
                <img
                  src={props.displayimage}
                  alt=""
                  className="object-center"
                />
              </div>
              <div className="px-2 py-3 border-t-2 lg:border-t-0 border-t-lime-300 flex flex-col justify-center h-full ">
                <div className="flex flex-wrap gap-2 text-sm my-2">
                  <div className="rounded-lg w-fit px-3 border border-[#2cb64c] text-[#2cb64c]">
                    {props.tipe}
                  </div>
                  <div className="rounded-lg w-fit px-3 border border-[#080a3f] text-[#080a3f]">
                    {props.sertifikat}
                  </div>
                  {props.program !== "-" && props.program !== "" ? (
                    <div className="rounded-lg w-fit px-3 border border-orange-600 text-orange-600">
                      {props.program}
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* {props.namaKompleks !== "-" && props.namaKompleks !== "" ? (
                    <div className="rounded-lg w-fit px-3 border border-[#ff2a2a] text-[#ff2a2a]">
                      {props.namaKompleks}
                    </div>
                  ) : (
                    <></>
                  )} */}
                </div>
                <h2 className="text-[12px] md:text-sm text-black italic">
                  {/* {props.hargaAwal !== 0 ? (
                    <>
                      <s>
                        Rp{" "}
                        {new Intl.NumberFormat("en-ID", {})
                          .format(props.hargaAwal)
                          .replace(/,/g, ".")}
                      </s>
                    </>
                  ) : (
                    <>
                      <div className="invisible">-</div>
                    </>
                  )} */}
                  Harga Mulai Dari :
                </h2>
                <Font family="Roboto">
                  <h2 className="text-xl lg:text-2xl font-bold">
                    Rp{" "}
                    {/* {new Intl.NumberFormat("en-ID", {})
                    .format(props.harga)
                    .replace(/,/g, ".")} */}
                    {shortHarga}
                  </h2>
                </Font>
                <p className="text-base lg:text-lg">
                  <Text family="Roboto">{props.taglineHook}</Text>
                </p>

                <p className="text-[11px] lg:text-[12px] opacity-50 italic">
                  {props.kelurahanOrKecamatan}, {props.kotaKab}
                </p>
                <div className="text-[13px] lg:text-sm flex space-x-1 my-3 text-red-800">
                  <Font family="Roboto">
                    <p>
                      {/* <FaStairs className="inline text-base me-1 lg:text-lg" />{" "}
                      {props.lantai}LT <span className="mx-1 md:mx-2">|</span> */}
                      <BiBed className="inline text-base me-1 lg:text-lg" />{" "}
                      {props.kamarTidur}KT{" "}
                      <span className="mx-1 md:mx-2">|</span>
                      <MdOutlineBathtub className="inline text-base me-1 lg:text-lg" />
                      {props.kamarMandi}KM{" "}
                      <span className="mx-1 md:mx-2">|</span> LB :{" "}
                      {props.luasBangunan}m<sup>2</sup>({props.lantai}Lt)
                      <span className="mx-1 md:mx-2">|</span>
                      LT : {props.luasTanah}m<sup>2</sup>{" "}
                    </p>
                  </Font>
                </div>
                {/* <div className="flex items-center text-[12px] font-bold my-2 lg:text-sm italic text-blue-500">
                  <div className="h-10 mr-3 aspect-square bg-white rounded-full w-fit overflow-hidden">
                    {props.member !== "" ? (
                      <>
                        <img src={props.fotoMember} alt={props.namaMember} />
                      </>
                    ) : (
                      <>
                        <img
                          src={dataMember[0].foto}
                          alt={dataMember[0].nama}
                        />
                      </>
                    )}
                  </div>
                  {`${
                    props.member !== ""
                      ? props.namaMember + " | " + props.nomorMember
                      : dataMember[0].nama + " | " + dataMember[0].nomor
                  }`}
                </div> */}
              </div>
            </div>
          </Link>
          <div className="text-center text-white mt-1 py-1 grid grid-cols-2 justify-items-center border-t-2 border-t-lime-300">
            <div className="flex items-center text-left text-[12px] my-2 lg:text-sm italic text-black col-span-2 justify-self-start mx-5">
              <div className="h-10 mr-3 aspect-square bg-white rounded-full w-fit overflow-hidden">
                {props.member !== "" &&
                new Date(props.expiredLinkMemberDate).valueOf() >=
                  new Date().valueOf() ? (
                  <>
                    <img src={props.fotoMember} alt={props.namaMember} />
                  </>
                ) : (
                  <>
                    <img src={props.fotoMarketing} alt={props.nameDisplay} />
                  </>
                )}
              </div>
              <div>
                <p className="not-italic font-bold ">
                  Hubungi Marketing Terbaik Kami :
                </p>
                {`${
                  props.member !== "" &&
                  new Date(props.expiredLinkMemberDate).valueOf() >=
                    new Date().valueOf()
                    ? props.namaMember + " ( " + props.nomorDisplayMember + " )"
                    : props.nameDisplay + " ( " + props.NumberDisplay + " )"
                  // : dataMember[0].nama +
                  //   " ( " +
                  //   dataMember[0].nomorDisplay +
                  //   " )"
                }`}
              </div>
            </div>
            <div className="w-auto text-black col-span-2 text-[12px] mx-2 lg:text-sm text-red-500 italic">
              <p>
                percayakan survey dan pembelian anda kepada{" "}
                <u>
                  {" "}
                  {props.member !== "" ? props.namaMember : props.nameDisplay}
                </u>
                , <br />{" "}
                <b>"jaminan pelanggan puas legalitas perumahan jelas</b>"
              </p>
            </div>
            <h6 className="space-x-1  bg-white border border-[#080a3f]  text-black w-fit px-5 py-2 rounded-lg hover:bg-gray-200 text-sm  lg:text-base flex justify-center items-center my-2 cursor-pointer">
              <div
                className="flex justify-around items-center gap-3"
                onClick={props.handleClikCall}
              >
                <BsFillTelephoneFill />
                <h1 className="">Telepon</h1>
              </div>
            </h6>
            <h6
              className="space-x-3 bg-green-600 w-fit px-5 py-2 rounded-lg hover:bg-green-800 text-sm lg:text-base flex justify-center items-center  my-2 cursor-pointer"
              onClick={props.handleClickChat}
            >
              <BsWhatsapp />
              <a href="/">Chat</a>
            </h6>
          </div>
        </div>
      </div>
    </>
  );
}

export default withDataListingPerumahan(ProductViews);
