import React, { useState, useEffect } from "react";
import { listingData } from "../data/listing";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

function ImageSlideComponent() {
  const url = new URLSearchParams(window.location.search).get("id");
  const [currentSlide, setCurrentSlide] = useState(0);
  const autoScroll = true;
  let slideInterval: any;
  let slideLength: any;
  let intervalTime = 3000;

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
    // console.log("next");
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
    // console.log("prev");
  };

  // function auto() {
  //   slideInterval = setInterval(nextSlide, intervalTime);
  // }

  const imageSlide = listingData.map((data: any, key: any) => {
    if (url === listingData[key].id.toString()) {
      slideLength = listingData[key].displayimage.length;
      return listingData[key].displayimage.map((data: any, index: any) => {
        return (
          <>
            <div
              className={`${
                index === currentSlide ? "slide current" : "slide"
              } w-full`}
              key={index}
            >
              {index === currentSlide && (
                <div className="h-full flex justify-center items-center object-contain ">
                  <img
                    src={listingData[key].displayimage[index]}
                    alt={listingData[key].perumahan}
                    className="h-full object-contain"
                  />
                </div>
              )}
            </div>
          </>
        );
      });
    }
  });

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  // useEffect(() => {
  //   if (autoScroll) {
  //     auto();
  //   }
  //   return () => clearInterval(slideInterval);
  // }, [currentSlide]);

  return (
    <>
      <section className="border-b-2 border-b-violet-400">
        <div>
          <div className="flex flex-row justify-center h-[50vh] lg:h-[50vh] w-full snap-x bg-gray-500  items-center">
            <div className="slider object-fill ">
              <AiOutlineArrowLeft
                className="arrow prev text-black"
                onClick={prevSlide}
              />
              <AiOutlineArrowRight className="arrow next" onClick={nextSlide} />
              {imageSlide}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ImageSlideComponent;
