import React from "react";
import { Link } from "react-router-dom";
import { dataMember } from "../data/member";

function MobileMenu(prop: { className?: string }) {
  let member = "";
  for (let i = 0; i < dataMember.length; i++) {
    if (
      window.location.pathname.split("/").includes(dataMember[i]["id_member"])
    ) {
      member = dataMember[i]["id_member"];
    }
  }

  let dataPartner = new Array();

  const openMenuMember = Object.keys(dataMember).map((val, key) => {
    dataPartner.push(dataMember[key].id_member);
  });

  const CheckMenuMember = () => {
    if (!dataPartner.includes(window.location.pathname.split("/").pop())) {
      return (
        <li className="border-b">
          <Link to="/partner">PARTNER</Link>
        </li>
      );
    } else {
      return <></>;
    }
  };

  const openMenuContactUs = Object.keys(dataMember).map((val, key) => {
    if (
      [window.location.pathname.split("/").pop()].includes(
        dataMember[key].id_member
      )
    ) {
      return (
        <li className="border-b">
          <Link
            to={`${member !== "" ? "/contact-us/" + member : "/contact-us/"}`}
          >
            KONTAK KAMI
          </Link>
        </li>
      );
    } else {
      return null;
    }
  });

  return (
    <>
      <div className={prop.className}>
        <ul className="flex flex-col justify-center items-center space-y-5 ">
          <li className="border-b">
            <Link to={`${member !== "" ? "/" + member : "/"}`}>HOME</Link>
          </li>
          {CheckMenuMember()}
          {openMenuContactUs}
          {/* <li className="border-b">
            <Link
              to={`${member !== "" ? "/contact-us/" + member : "/contact-us/"}`}
            >
              KONTAK KAMI
            </Link>
          </li> */}
        </ul>
      </div>
    </>
  );
}

export default MobileMenu;
