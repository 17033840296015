import React from "react";
import Logo from "../../components/Logo";

function Login() {
  return (
    <>
      <section className="h-[100vh] w-[100vw] flex  justify-center items-center bg-lime-900">
        <div className="w-1/2 justify-center items-center bg-lime-200">
          <Logo className={`text-center`} mitra="" />
          <div></div>
        </div>
      </section>
    </>
  );
}

export default Login;
