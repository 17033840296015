import React, { useState } from "react";

// component
import Logo from "./Logo";
import Menu from "./Menu";

// icon - icon
import { FiMenu } from "react-icons/fi";
import { RiMenuUnfoldLine } from "react-icons/ri";

function NavMenuHorizontal(prop: {
  className?: string;
  onClick?: (e: any) => void;
  textColor?: string;
  mitra: string;
}) {
  // setFilterHideMobile
  const [hideFilterCategory, setHideFilterCategory] = useState("-left-[100%]");
  const [colorActiveFilter, setColorActiveFilter] = useState("text-white");
  const handleClickHideFilter = (e: any) => {
    e.preventDefault();
    if (hideFilterCategory.split(" ").includes("-left-[100%]")) {
      setHideFilterCategory("left-[0%]  fade-right-filter");
      setColorActiveFilter("text-violet-400");
    } else {
      setHideFilterCategory("-left-[100%]  fade-left-filter");
      setColorActiveFilter("text-white");
    }
  };

  return (
    <>
      <div
        className={`flex flex-col justify-between items-center px-2 text-white my-3 md:my-5 w-full`}
      >
        <div className="flex justify-between items-center w-full md:w-11/12 ">
          <div className="flex justify-center items-center ">
            <Logo mitra={prop.mitra} />
          </div>
          <FiMenu
            className={`lg:hidden text-3xl ${prop.className}`}
            onClick={prop.onClick}
          />
          <Menu className="hidden lg:block" />
        </div>
      </div>
    </>
  );
}

export default NavMenuHorizontal;
