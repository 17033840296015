import React from "react";
import { dataMember } from "../data/member";
import { Link } from "react-router-dom";

export default function Menu(prop: { className?: String }) {
  let member = "";
  for (let i = 0; i < dataMember.length; i++) {
    if (
      window.location.pathname.split("/").includes(dataMember[i]["id_member"])
    ) {
      member = dataMember[i]["id_member"];
    }
  }

  let dataPartner = new Array();

  const openMenuMember = Object.keys(dataMember).map((val, key) => {
    dataPartner.push(dataMember[key].id_member);
  });

  const CheckMenuMember = () => {
    if (!dataPartner.includes(window.location.pathname.split("/").pop())) {
      return (
        <li className="">
          <Link to="/partner">PARTNER</Link>
        </li>
      );
    } else {
      return <></>;
    }
  };

  const openMenuContactUs = Object.keys(dataMember).map((val, key) => {
    if (
      [window.location.pathname.split("/").pop()].includes(
        dataMember[key].id_member
      )
    ) {
      return (
        <li className="">
          <Link
            to={`${member !== "" ? "/contact-us/" + member : "/contact-us/"}`}
          >
            KONTAK KAMI
          </Link>
        </li>
      );
    } else {
      return null;
    }
  });

  return (
    <>
      <div className={`${prop.className}`}>
        <ul className="flex space-x-8">
          <li>
            <Link to={`${member !== "" ? "/" + member : "/"}`}>HOME</Link>
          </li>
          {CheckMenuMember()}
          {openMenuContactUs}
          {/* <li>
            <Link
              to={`${member !== "" ? "/contact-us/" + member : "/contact-us/"}`}
            >
              Kontak Kami
            </Link>
          </li> */}
        </ul>
      </div>
    </>
  );
}
