import React from "react";
import Font, { Text } from "react-font";

export default function Footer(prop: { className?: string }) {
  return (
    <footer className={`${prop.className}`}>
      <section className="w-full bg-[#080a3f] text-white flex items-center justify-center h-full">
        <div className="w-full h-full ">
          <h6 className="flex items-center justify-center h-full">
            <Text family="Montserrat">Copyright @2023 RUMAH PRIMER</Text>
          </h6>
        </div>
      </section>
    </footer>
  );
}
