import React from "react";
import { listingData } from "../data/listing";
import { dataMember } from "../data/member";
import ProductViews from "./ProductViews";

function RecommendProducts(prop: any) {
  const url = new URLSearchParams(window.location.search).get("id");
  let city: any;
  let price: any;
  let typeproperty: any;
  listingData.map((val: any, key: any) => {
    if (url === listingData[key].id.toString()) {
      city = listingData[key].kotaKab;
      price = listingData[key].harga;
      typeproperty = listingData[key].tipe;
    }
  });

  let cekID = new Array();
  let totalData = 0;
  let num = 0;

  // Jika Terdapat harga antara
  const productRecomend = listingData
    .sort(() => Math.random() - Math.random())
    .map((val: any, key: any) => {
      let check = true;
      let dataSementara1 = dataMember
        .sort(() => Math.random() - Math.random())
        .sort(() => Math.random() - Math.random())
        .sort(() => Math.random() - Math.random())[0];
      while (check) {
        if (
          new Date(dataSementara1.expiredDateMember).valueOf() >=
          new Date().valueOf()
        ) {
          check = false;
        } else {
          dataSementara1 = dataMember
            .sort(() => Math.random() - Math.random())
            .sort(() => Math.random() - Math.random())[0];
        }
      }

      if (
        city === listingData[key].kotaKab &&
        typeproperty === listingData[key].tipe &&
        listingData[key].harga > price - 100000000 &&
        listingData[key].harga < price + 100000000 &&
        url !== listingData[key].id.toString() &&
        num !== 5 &&
        !dataMember[prop.idArrayMember].exceptionShowListing.includes(
          listingData[key].perumahan
        )
      ) {
        cekID.push(key);
        totalData++;
        num++;
        return (
          <ProductViews
            harga={listingData[key].harga}
            kamarTidur={listingData[key].kamarTidur}
            kotaKab={listingData[key].kotaKab}
            displayimage={listingData[key].displayimage[0]}
            kamarMandi={listingData[key].kamarMandi}
            luasTanah={listingData[key].luasTanah}
            luasBangunan={listingData[key].luasBangunan}
            tipe={listingData[key].tipe}
            taglineHook={listingData[key].taglineHook}
            kelurahanOrKecamatan={listingData[key].kelurahanOrKecamatan}
            namaKompleks={listingData[key].namaKompleks}
            id={listingData[key].id}
            hargaAwal={listingData[key].hargaAwal}
            sertifikat={listingData[key].sertifikat}
            lantai={listingData[key].lantai}
            program={listingData[key].program}
            nameDisplay={dataSementara1.nama}
            NumberDisplay={dataSementara1.nomorDisplay}
            fotoMarketing={dataSementara1.foto}
            classNameGrid="grid grid-cols-1 "
          />
        );
      }
    });

  // Jika
  const productAddiotional = listingData
    .sort(() => Math.random() - Math.random())
    .map((val: any, key: any) => {
      let check = true;
      let dataSementara2 = dataMember
        .sort(() => Math.random() - Math.random())
        .sort(() => Math.random() - Math.random())[0];
      while (check) {
        if (
          new Date(dataSementara2.expiredDateMember).valueOf() >=
          new Date().valueOf()
        ) {
          check = false;
        } else {
          dataSementara2 = dataMember
            .sort(() => Math.random() - Math.random())
            .sort(() => Math.random() - Math.random())[0];
        }
      }

      if (
        city === listingData[key].kotaKab &&
        typeproperty === listingData[key].tipe &&
        url !== listingData[key].id.toString() &&
        totalData !== 5 &&
        !cekID.includes(listingData[key].id) &&
        !dataMember[prop.idArrayMember].exceptionShowListing.includes(
          listingData[key].perumahan
        )
      ) {
        totalData++;
        cekID.push(key);
        return (
          <ProductViews
            harga={listingData[key].harga}
            kamarTidur={listingData[key].kamarTidur}
            kotaKab={listingData[key].kotaKab}
            displayimage={listingData[key].displayimage[0]}
            kamarMandi={listingData[key].kamarMandi}
            luasTanah={listingData[key].luasTanah}
            luasBangunan={listingData[key].luasBangunan}
            tipe={listingData[key].tipe}
            taglineHook={listingData[key].taglineHook}
            kelurahanOrKecamatan={listingData[key].kelurahanOrKecamatan}
            id={listingData[key].id}
            namaKompleks={listingData[key].namaKompleks}
            hargaAwal={listingData[key].hargaAwal}
            sertifikat={listingData[key].sertifikat}
            lantai={listingData[key].lantai}
            program={listingData[key].program}
            nameDisplay={dataSementara2.nama}
            NumberDisplay={dataSementara2.nomorDisplay}
            fotoMarketing={dataSementara2.foto}
            classNameGrid="grid  grid-cols-1 "
          />
        );
      }
    });

  const productAddiotional2 = listingData
    .sort(() => Math.random() - Math.random())
    .map((val: any, key: any) => {
      let check = true;
      let dataSementara3 = dataMember
        .sort(() => Math.random() - Math.random())
        .sort(() => Math.random() - Math.random())[0];
      while (check) {
        if (
          new Date(dataSementara3.expiredDateMember).valueOf() >=
          new Date().valueOf()
        ) {
          check = false;
        } else {
          dataSementara3 = dataMember
            .sort(() => Math.random() - Math.random())
            .sort(() => Math.random() - Math.random())[0];
        }
      }

      if (
        typeproperty === listingData[key].kotaKab &&
        url !== listingData[key].id.toString() &&
        totalData !== 5 &&
        !cekID.includes(listingData[key].id) &&
        !dataMember[prop.idArrayMember].exceptionShowListing.includes(
          listingData[key].perumahan
        )
      ) {
        totalData++;
        cekID.push(key);
        return (
          <ProductViews
            harga={listingData[key].harga}
            kamarTidur={listingData[key].kamarTidur}
            kotaKab={listingData[key].kotaKab}
            displayimage={listingData[key].displayimage[0]}
            kamarMandi={listingData[key].kamarMandi}
            luasTanah={listingData[key].luasTanah}
            luasBangunan={listingData[key].luasBangunan}
            tipe={listingData[key].tipe}
            taglineHook={listingData[key].taglineHook}
            kelurahanOrKecamatan={listingData[key].kelurahanOrKecamatan}
            id={listingData[key].id}
            namaKompleks={listingData[key].namaKompleks}
            hargaAwal={listingData[key].hargaAwal}
            sertifikat={listingData[key].sertifikat}
            lantai={listingData[key].lantai}
            program={listingData[key].program}
            nameDisplay={dataSementara3.nama}
            NumberDisplay={dataSementara3.nomorDisplay}
            fotoMarketing={dataSementara3.foto}
            classNameGrid="grid  grid-cols-1 "
          />
        );
      }
    });

  const productAddiotional3 = listingData
    .sort(() => Math.random() - Math.random())
    .map((val: any, key: any) => {
      let check = true;
      let dataSementara4 = dataMember
        .sort(() => Math.random() - Math.random())
        .sort(() => Math.random() - Math.random())[0];
      while (check) {
        if (
          new Date(dataSementara4.expiredDateMember).valueOf() >=
          new Date().valueOf()
        ) {
          check = false;
        } else {
          dataSementara4 = dataMember
            .sort(() => Math.random() - Math.random())
            .sort(() => Math.random() - Math.random())[0];
        }
      }

      if (
        typeproperty === listingData[key].tipe &&
        url !== listingData[key].id.toString() &&
        totalData !== 5 &&
        !cekID.includes(listingData[key].id) &&
        !dataMember[prop.idArrayMember].exceptionShowListing.includes(
          listingData[key].perumahan
        )
      ) {
        totalData++;
        cekID.push(key);
        return (
          <ProductViews
            harga={listingData[key].harga}
            kamarTidur={listingData[key].kamarTidur}
            kotaKab={listingData[key].kotaKab}
            displayimage={listingData[key].displayimage[0]}
            kamarMandi={listingData[key].kamarMandi}
            luasTanah={listingData[key].luasTanah}
            luasBangunan={listingData[key].luasBangunan}
            tipe={listingData[key].tipe}
            taglineHook={listingData[key].taglineHook}
            kelurahanOrKecamatan={listingData[key].kelurahanOrKecamatan}
            namaKompleks={listingData[key].namaKompleks}
            id={listingData[key].id}
            hargaAwal={listingData[key].hargaAwal}
            sertifikat={listingData[key].sertifikat}
            lantai={listingData[key].lantai}
            program={listingData[key].program}
            nameDisplay={dataSementara4.nama}
            NumberDisplay={dataSementara4.nomorDisplay}
            fotoMarketing={dataSementara4.foto}
            classNameGrid="grid  grid-cols-1 "
          />
        );
      }
    });

  const productAddiotional4 = listingData
    .sort(() => Math.random() - Math.random())
    .map((val: any, key: any) => {
      let check = true;
      let dataSementara5 = dataMember
        .sort(() => Math.random() - Math.random())
        .sort(() => Math.random() - Math.random())[0];
      while (check) {
        if (
          new Date(dataSementara5.expiredDateMember).valueOf() >=
          new Date().valueOf()
        ) {
          check = false;
        } else {
          dataSementara5 = dataMember
            .sort(() => Math.random() - Math.random())
            .sort(() => Math.random() - Math.random())[0];
        }
      }

      if (
        url !== listingData[key].id.toString() &&
        totalData !== 5 &&
        !cekID.includes(listingData[key].id) &&
        !dataMember[prop.idArrayMember].exceptionShowListing.includes(
          listingData[key].perumahan
        )
      ) {
        totalData++;
        cekID.push(key);
        return (
          <ProductViews
            harga={listingData[key].harga}
            kamarTidur={listingData[key].kamarTidur}
            kotaKab={listingData[key].kotaKab}
            displayimage={listingData[key].displayimage[0]}
            kamarMandi={listingData[key].kamarMandi}
            luasTanah={listingData[key].luasTanah}
            luasBangunan={listingData[key].luasBangunan}
            tipe={listingData[key].tipe}
            taglineHook={listingData[key].taglineHook}
            kelurahanOrKecamatan={listingData[key].kelurahanOrKecamatan}
            id={listingData[key].id}
            namaKompleks={listingData[key].namaKompleks}
            hargaAwal={listingData[key].hargaAwal}
            sertifikat={listingData[key].sertifikat}
            lantai={listingData[key].lantai}
            program={listingData[key].program}
            nameDisplay={dataSementara5.nama}
            NumberDisplay={dataSementara5.nomorDisplay}
            fotoMarketing={dataSementara5.foto}
            classNameGrid="grid  grid-cols-1 "
          />
        );
      }
    });

  return (
    <>
      {productRecomend}
      {productAddiotional}
      {productAddiotional2}
      {productAddiotional3}
      {productAddiotional4}
    </>
  );
}

export default RecommendProducts;
